  .menuToggle {
    display: none;
  }
// navbar second style
.fixed {
  position: sticky !important;
  top: 0  !important;
  left: 0 !important;
  width: 100%;
  z-index: 8;
  box-shadow: 0 2px 24px 0 rgba(61, 55, 55, 0.15);
  background-color: #ffffff !important;
  // animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
}

.navbar .navbar-nav .nav-link {
  color: #082859;
  font-size: 1.1em;
}
.navbar .navbar-nav .nav-link:hover{
  color: #808080;
}
@media only screen and (min-width: 992px) {
  .navbar {
    flex-direction: column;
  }
}
li.nav-item.nav-link-divider {
  margin: 10px 10px;
  color: #36c77a;
}

@media (max-width: 991.98px) {
  .menuToggle {
    display: block;
  }
  .nav-link-divider{
    display: none;
  }
  .navbar.navbar-expand-lg.navbar-light.bg-light {
    padding: 5px 20px;
  }
  div#navbarNav {
    margin-top: 8px;
    border-top: 1px solid #e5e5e5;
  }

}
