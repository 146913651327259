.testimonials
{
    .section1
    {
        min-height: 80px;
       // background-color:antiquewhite;
        width: 100%;
        padding: 10px 120px 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .wrapper1
        {
            min-height: 130px;
           // background-color: aqua;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: 40px;
            margin-bottom: 30px;


            h3{
                font-size: $xsmallHeading - 1;
                text-transform: uppercase;
                color: $light-purple-color;
                font-weight: $heavy-bold;
            }


        }
    }



    .section2{

        min-height: 350px;
        background-color:$white-color;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;           

        .container1
        {
            padding: 0;
            margin: 0;
            .wrapper
            {
                padding: 20px 100px 20px;
                min-height: 180px;
            // background-color: rgb(189, 189, 130);
            //background-color: $black-color-2;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0px;

                .box1{
                    padding-right: 35px;
                    // min-height: 350px;
                // background-color: burlywood;
                    margin: 0px;
                    color: $white-color;
                    
                    

                    h3{
                        font-size: $heading2;
                        font-weight: $heavy-bold;
                        color: $light-purple-color;
                        letter-spacing: 0.5px;
                        text-transform: uppercase;
                        margin-bottom: 20px;
                        
                    }
                    p{
                        font-size: $regularFont;
                        font-weight: $regular;
                        padding-right: 25px;
                        text-decoration: none;
                        color: $black-color-2;

                        i
                        {
                            margin-left: 5px;
                            font-size: $mediumFont;
                        }
                    }
                }

                .box2{
                    // min-height: 350px;
                // background-color: rgb(124, 76, 13);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 0px;
                    padding: 0px;

                    .img-box{

                        min-height: 180px;
                        width: 100%;
                        //background-color: cornflowerblue;

                        img{
                            width: 100%;
                            height: 100%;
                            object-fit: cover !important;
                        }
                    }

                }
            }
        } 


        .container2
        {
            padding: 0px;
            margin: 0px;

            .wrapper
            {
                min-height: 180px;
                padding: 100px 100px 130px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0px;
                background-size: cover;
                //background-attachment: fixed;
                //background-color: $black-color-2;
                background-repeat: no-repeat;
                background-position:center;


                .box1{
                    // min-height: 350px;
                // background-color: rgb(124, 76, 13);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 0px;
                    padding: 0px;

                    .img-box{

                        min-height: 180px;
                        width: 100%;
                        //background-color: cornflowerblue;

                        img{
                            width: 100%;
                            height: 100%;
                            object-fit: cover !important;
                        }
                    }

                }

                .box2{

                    // min-height: 350px;
                // background-color: burlywood;
                    margin: 0px;
                    padding-left: 70px;
                    

                    h3{
                        font-size: $heading2;
                        font-weight: $heavy-bold;
                        letter-spacing: 0.5px;
                        text-transform: uppercase;
                        margin-bottom: 20px;
                        color: $light-purple-color;
                    }
                    p{
                         font-size: $regularFont;
                        font-weight: $regular;
                        color: $black-color-2;
                    }
                }
            }
        } 
        
    }

    .section12{
        padding-top: 3rem;
        .container1{
            padding-top: 2rem;
            .wrapper{
                padding: 10px;
                .content{
                   padding-left: 20px;
                   
                   h3{
                    font-size: 1.8rem;
                    font-weight: 700;
                    text-transform: uppercase;
                    font-style: bold;
                    color: $blue-color;
                    letter-spacing: 2px;
                   }
                }
                .img-boxx{
                    background-image: $background-gradient;
                    border-radius: 30px;
                    width: 350px; 
                    height: 350px;
                    img{
                        position: relative;
                        top: 10%;
                        left: 20%;
                        width: 300px;
                        height: 350px;
                        border-radius: 30px;
                        object-fit: cover;
                        object-position: 10% 90%;
                        
                        
                    }
                }
                .img-box{
                    background-image: $background-gradient;
                    border-radius: 30px;
                    width: 350px; 
                    height: 350px;
                    img{
                        position: relative;
                        top: 10%;
                        left: 20%;
                        width: 300px;
                        height: 350px;
                        border-radius: 30px;
                        object-fit: cover;
                        object-position: 80% 20%;
                        
                        
                    }
                    
                }
                
            }

        }
    }

}

@media screen and (max-width: 768px) {

.testimonials
{

    .section1{

        padding: 45px 35px 25px !important;

    }

    
    .section2{

        .container1
        {
            .wrapper
            {
                padding: 0px 35px 45px !important;
               

                .box1{
                      
                    padding-right: 0px;
                    padding-left: 0px;
                  

                    h3{
                    
                        text-align: center;
                        
                    }
                    p{
                        font-size: $smallFont;
                        text-align: justify;
                        font-weight: $medium;
                        padding-right: 0px;
                    }
                }

              
            }
        } 


        .container2
        {

            .wrapper
            {
                min-height: 180px;
                padding: 45px 35px 75px !important;
               

                .box2{

                    // min-height: 350px;
                // background-color: burlywood;
                    margin: 0px;
                    padding: 0px !important;
                    

                    h3{
                        text-align: center;
                    }
                    p{
                        font-size: $smallFont;
                        text-align: justify;
                        font-weight: $medium;
                        padding-right: 0px;
                    }
                }
            }
        } 
        
    }
    .section12{
        padding-top: 3rem;
        .container1{
            .wrapper{
               display: flex;
               justify-content: center;
               align-items: center;
               padding: 10px;
                .content{
                   padding-left: 20px;
                   
                   h3{
                    font-size: 1.2rem;
                    font-weight: 700;
                    font-style: bold;
                    color: $blue-color;
                   }
                   p{
                    font-size: 0.8rem;
                   }
                }
                .img-boxx{
                    background-image: $background-gradient;
                    border-radius: 30px;
                    width: 220px; 
                    height: 300px;
                    img{
                        
                        width: 200px;
                        height: 300px;
                        border-radius: 30px;
                       
                    }
                }
                .img-box{
                    background-image: $background-gradient;
                    border-radius: 30px;
                    width: 220px; 
                    height: 300px;
                    img{
                        
                        width: 200px;
                        height: 300px;
                        border-radius: 30px;
                       
                    }
                }
            }

        }
    }

}
}
@media screen and (max-width: 320px){
    .testimonials{
        .section12{
            scroll-behavior: smooth;
            padding-top: 3rem;
            .container1{
                .wrapper{
                    margin: 0px;
                    .row {
                        --bs-gutter-x: 0rem;
                    }
                    padding: 10px;
                    .content{
                       padding-left: 20px;
                       
                       h3{
                        font-size: 1rem;
                        font-weight: 700;
                        font-style: bold;
                        color: $blue-color;
                        letter-spacing: normal;
                       }
                       p{
                        font-size: 0.8rem;
                       }
                    }
                    .img-boxx{
                        background-image: $background-gradient;
                        border-radius: 30px;
                        width: 220px; 
                        height: 300px;
                        margin-left: -30px;
                        img{
                            
                            width: 220px;
                            height: 300px;
                            border-radius: 30px;
                            
                        }
                    }
                    .img-box{
                        background-image: $background-gradient;
                        border-radius: 30px;
                        width: 220px; 
                        height: 300px;
                        margin-left: -30px;
                        img{
                            
                            width: 220px;
                            height: 300px;
                            border-radius: 30px;
                            
                        }
                    }
                }
    
            }
        }
    }

}