.porch
{

    // .space{

    //     margin-top: 100px;
    // }

    //padding-bottom: 300px;
    .section1
    {
        // min-height: 400px;
       // background-color:antiquewhite;
        // width: 100%;
        // padding: 0px 120px 50px;
        // display: flex;
        // flex-direction: column;
        // justify-content: center;
        // align-items: center;
        .sec2-content {
            text-align: center;
            margin: 50px;
        }

        .wrapper1
        {
            min-height: 130px;
           // background-color: aqua;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: 40px;
            margin-bottom: 30px;

            p{
                line-height: 10px;
            }
        }
        
        .wrapper2
        {
            min-height: 180px;
        // background-color: rgb(189, 189, 130);
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0px;
            padding-top: 50px;

            .box1{

                // min-height: 350px;
            // background-color: burlywood;
                padding-right: 30px !important;

                a{
                    color: $light-purple-color;
                    font-weight: $heavy-bold;
                }
                

                h6{
                   font-size: $regularFont;
                    letter-spacing: 0px;
                    margin-bottom: 30px;
                    font-weight: $semi-bold;
                    line-height: 22px;
                    text-align: justify;

                }
                ul{
                    color: $black-color;
                    padding-left: 1rem !important;
                    li{
                        text-align: justify;
                        margin-bottom: 8px;
                        line-height: 20px;
                    }
                }
            }

            // .box2{
            //     // min-height: 350px;
            // // background-color: rgb(124, 76, 13);
            //     display: flex;
            //     justify-content: center;
            //     align-items: center;
            //     margin: 0px;
            //     padding: 0px;
            //     padding-left: 30px;

            //     .img-box{

            //         min-height: 180px;
            //         width: 100%;
            //         //background-color: cornflowerblue;

            //         img{
            //             width: 100%;
            //             height: 100%;
            //             object-fit: cover !important;
            //         }
            //     }

            // }
        }
        
        .nav-request
        {
            background-color: $black-color;
            color: $white-color !important;
            font-weight: $regular !important;
            padding: 12px 20px;
            border-radius: 30px;
            font-size: 16px !important;
            text-decoration: none !important;
        
        }


    }

    // .section2
    // {
    //     min-height: 250px;
    //     background-color: $black-color-2;
    //     width: 100%;
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    //     background-position: center;
    //     background-size: cover;
    //     background-repeat: no-repeat;
    //     background-attachment: fixed;
    //     padding: 0px 30px;
    //     margin-top: 20px;

    //     h2{
    //         color: $white-color;
    //         font-size: $mediumHeading - 3;
    //         margin-top: 10px;
    //         text-transform: uppercase;
    //         font-weight: $bold;
    //         text-align: center;
    //     }
    // }

    .section3
    {
        min-height: 400px;
        // background-color:antiquewhite;
        width: 100%;
        padding: 0px 120px 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .req-quote-div {
            text-align: center;
            margin: 50px;
        }
        .req-quote-btn{
            margin: 0px !important;
        }
    }
    .row.row-bg {
        background-size: 900px;
        background-repeat: no-repeat;
        background-position-x: 230px;
        background-position-y: bottom;
        margin-top: 50px;
        // margin-bottom: 150px;
    }
    // .row-bg :nth-child(2) .full-card{
    //     position: absolute;
    //     margin-top: 100px;
    //     margin-left: 90px;

    // }
    .card.full-card {
        width: 450px;
        border-radius: 50px;
        margin:auto;
    }
    img.card-img-top {
        border-top-left-radius: 50px;
        border-top-right-radius: 50px;
    }
    .full-card .card-body {
        height: 500px;
        background-position: right;
        border-radius: 50px;
        margin-top: -50px;
        padding: 50px 30px;
        text-align: center;
    }
    .card-title {
        color: #66df7e;
        font-weight: 700;
        margin-top: 25px;
    }
    .card-body ul {
        list-style: none;
        padding: 0;
        line-height: 2;
        text-align: left;
    }
    .card-body ul li {
        display: flex;
        color: white;
    }
    .card-body ul li:before {
        content: "";
        background: linear-gradient(0deg, #46d07b 0%, #0ca044 100%);
        display: block;
        width: 25px;
        height: 3px;
        margin: 13px 25px 0 0;
    }
    .card-body ul li p {
        margin-bottom: 0;
        width: 90%;
    }
    
   
}


@media only screen and (max-width: 2900px) and (min-width: 1600px)  {

    // .porch
    // {
    //     .section1
    //     {
            
    //         .wrapper2
    //         {
            
    
            //   .box2{
            //     padding: 0px 0px 0px 80px !important;
            //   }
    
    //         }
            
    //     }

     
    // }


}


@media only screen and (max-width: 1250px) and (min-width: 780px)  {

.porch{

    .space{

        margin-top: 70px ;
    }


    .section1
    {
        min-height: 400px;
       // background-color:antiquewhite;
        width: 100%;
        padding: 0px 90px 50px;
    }

    .section2
    {
        margin-top: 0px;
    }
}
    
}


@media screen and (max-width:768px)
{
    .porch
    {
        .section3{
            padding: 0px;
        }
        .row.row-bg {
            background-image: none !important;
            margin-top: 0px;
            margin-bottom: 50px;
        }
        .card.full-card {
            width: 388px;
        }
        .row-bg :nth-child(2) .full-card {
            margin-top: 50px;
            margin-left: 0px;
            position: unset;
        }
        
        .space{

            margin-top: 30px ;
        }

        .section1
        {
            //background-color:antiquewhite;
            // padding: 0px 40px 50px;
            min-height: 400px;
            width: 100%;
            padding-top: 50px;

            .sec2-content {
                text-align: justify;
            }

            .wrapper1
            {
                min-height: 180px;
                padding-top: 30px;
    
                p{
                    line-height: 23px;
                    font-size: $smallFont + 1 !important;
                }
            }

            .wrapper2
            {
                padding-top: 30px !important;
                margin: 0px;
    
                .box1{
    
                    // min-height: 350px;
                // background-color: burlywood;
                    padding-left: 3px !important;
                    padding-right: 0px !important;
                    padding-bottom: 50px !important;
                    
    
                    h6{
                       font-size: $smallFont + 1;
                        letter-spacing: 0.5px;
                        margin-bottom: 20px;
                        font-weight: $semi-bold;
                        text-align: justify;
                    }
                    ul{
                        color: $black-color;
                        padding-left: 1rem !important;
                        li{
                            margin-bottom: 5px;
                            font-size:  $smallFont;
                            text-align: justify;
                        }
                    }
                }

                // .box2{
                //     padding-left: 0px !important;
                // }
    
            }
            
        }

        .section2
        {
            min-height: 150px;
            margin-top: 30px;
            h2{
                color: $white-color;
                font-size: $smallHeading - 5 !important;
                text-transform: uppercase;
                font-weight: $bold;
            }
        }
    }
}