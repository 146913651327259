.thankyou-title-section {
    .container {
        padding: 6%;
      .wrapper1 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 30px;
        text-align: center;
  
        h6 {
          justify-content: center;
          font-size: 25px;
          font-weight: 700;
          padding: 40px 40px 0 40px;
        }
      }
      .thankyou-btn{
        padding: 5% 0 0% 0;
        text-align: center;
      }
    }
  }