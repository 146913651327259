.banner {
  //padding-bottom: 300px;
  .b-section1 {
    min-height: 420px;
    width: 100%;
    background-size: cover;
    background-color: $black-color-2;
    background-repeat: no-repeat;
    background-position: 50% 20%;
  }
  .align-items-center {
    height: 700px;
  }
  img.ban-top-image {
    position: absolute;
    right: -3px;
    top: 269px;
  }
  h3.ban-top-text {
    font-size: 67px;
    font-weight: 800;
    margin-left: 100px;
    line-height: 1.3;
    color: white;
  }

  // .b-section2 {
  //     min-height: 80px;
  //     background-color: $purple-color;
  //     width: 100%;
  //     padding: 20px 120px;
  //     margin-top: -120px;
  //     opacity: 0.7;

  //     .wrapper1 {
  //         min-height: 80px;
  //         //background-color: antiquewhite;
  //         display: flex;
  //         align-items: center;
  //         justify-content: center;
  //         text-align: center;

  //         h3 {
  //             color: $white-color;
  //             margin-bottom: 0px;
  //             text-transform: uppercase;
  //             text-align: center;
  //             font-size: $mediumHeading - 2;
  //             font-weight: $bold;
  //         }
  //     }

  //     .wrapper2 {
  //         min-height: 80px;
  //         //background-color: antiquewhite;
  //         display: flex;
  //         align-items: center;
  //         position: relative;
  //         display: flex;
  //         justify-content: center;
  //         align-items: center;

  //         .imgBox {
  //             height: auto;
  //             width: 70%;
  //             //background-color: aqua;
  //             position: absolute;
  //             margin-top: -90px;

  //             img {
  //                 width: 100%;
  //             }
  //         }

  //     }
  // }
}

.banner2 {
  min-height: 220px;
  background-color: $black-color-2;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 0px 30px;

  h2 {
    color: $white-color;
    font-size: $largeHeading - 5;
    text-transform: uppercase;
    font-weight: $bold;
  }
}

.banner-style-3 {
  background-size: 100%;
  // height: 558px;
  background-repeat: no-repeat;
  // $banner-height: 550px;

  .wrapper {
    // min-height: $banner-height;
    // width: 100%;
    // background-blend-mode: overlay;
    // background-color: #09182DD4;
    // background-repeat: no-repeat;
    // background-size: cover;

    .row {
      // min-height: $banner-height;

      .col-lg-6 {
        margin: auto;
        // margin-top: 275px;

        h1 {
          text-transform: uppercase;
          font-size: 2rem;
          font-weight: $semi-bold;
          margin-bottom: 100px;
        }
      }
    }
  }
}

.banner-style-4 {
  // position: relative;
  // background-color: $background-color1;
  background-position: center;
  height: 700px;
  background-repeat: no-repeat;

  .row > {
    padding-left: 0;
  }

  .wrapper {
    padding: 0;
    margin: 0;

    // .col-bg-img {
    //     background-image: url('./images/testmonials/testimonial-banner.jpg');
    //     background-repeat: no-repeat;
    //     background-position: center;
    //     background-size: cover;

    //     .col-1-content {
    //         display: flex;
    //         justify-content: space-between;
    //         align-items: center;

    //         img {

    //             width: 200px;
    //             height: 200px;
    //             z-index: 2;
    //         }

    //         h1 {
    //             color: white;
    //             font-size: 1.8rem;
    //             z-index: 2;

    //         }
    //     }
    // }

    .banner4-content {
      // display: flex;
      // flex-direction: column;
      // padding-top: 4.9rem;
      text-align: center;
      margin-top: 350px;

      h1 {
        color: white;
        font-size: 75px;
        font-weight: 500;
        // z-index: 2;

        span {
          color: $green-color;
          font-style: bold;
          font-weight: 700;
        }
      }

      // p {
      //     margin-top: 10rem;
      //     font-size: 1.4rem;
      //     line-height: 28px;
      // }
    }

    // .banner4-hover {
    //     position: absolute;
    //     width: 100%;
    //     top: 0%;
    //     display: flex;

    //     >div {
    //         flex: 1;
    //         position: absolute;
    //         right: 0%;
    //         z-index: 1;
    //         width: 10px;
    //         height: 200px;
    //         background-color: $blue-color;
    //         animation: increase 5s;
    //         animation-timing-function: ease-in;
    //         animation-fill-mode: forwards;
    //         opacity: 0.9;
    //     }

    //     @keyframes increase {

    //         100% {
    //             width: 100%;
    //         }
    //     }

    // }
  }

  // .banner4-border {
  //     width: 100%;
  //     height: 25px;
  //     position: relative;

  //     border-bottom-left-radius: 25px;
  //     border-bottom-right-radius: 25px;
  //     background-color: #09182D;
  // }
}

.banner-style-5 {
  // background-blend-mode: overlay;
  // background-color: #09182DD4;
  background-repeat: no-repeat;
  background-size: cover;

  .row {
    [class*="col-"] {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .text-wrapper {
        position: relative;

        .text-content {
          position: relative;
          z-index: 1;
          margin: 0 0 0 18px;

          .banner-title {
            color: $white-color;
            font-weight: 700;
            text-transform: uppercase;
            font-size: 50px;
          }
        }

        .text-bg {
          background: $background-gradient;
          width: 290px;
          height: 270px;
          border-radius: 20px;
          border-bottom-left-radius: 120px;
          position: absolute;
          top: -70px;
          z-index: 0;
        }
      }
    }
  }
}

.banner-style-6 {
  // background-blend-mode: overlay;
  // background-color: #09182DD4;
  background-repeat: no-repeat;
  background-size: cover;

  .row {
    [class*="col-"] {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .text-wrapper {
        position: relative;

        .text-content {
          position: relative;
          z-index: 1;
          margin: 0 0 0 18px;

          .banner-title {
            color: $white-color;
            font-weight: 700;
            text-transform: uppercase;
            font-size: 50px;
            margin-left: 7%;
          }
        }

        .text-bg {
          background: $background-gradient;
          width: 200px;
          height: 180px;
          border-radius: 20px;
          border-bottom-right-radius: 120px;
          position: absolute;
          top: -70px;
          z-index: 0;
        }
      }
    }
  }
}

@media only screen and (max-width: 2900px) and (min-width: 1600px) {
  .banner {
    //padding-bottom: 300px;
    .b-section1 {
      min-height: 490px;
    }

    .banner4-content {
      width: 200px;
    }
  }

  .banner2 {
    min-height: 280px;
  }
}

@media screen and (max-width: 768px) {
  .banner-style-4 {
    .wrapper {
      .col-bg-img {
        .col-1-content {
          img {
            width: 150px;
            height: 200px;
            z-index: 2;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .banner {
    .b-section1 {
      background-position: 31% 50% !important;
    }

    // .b-section2 {
    // min-height: 400px;
    // padding: 20px 35px;

    // .wrapper1 {
    //     min-height: 80px;
    //     justify-content: center;

    //     h3 {

    //         text-align: center;
    //         font-size: $smallHeading - 4;
    //         line-height: 32px;
    //         //padding-bottom: 10px;
    //     }
    // }

    // .wrapper2 {

    // display: flex;
    // align-items: center;
    // position: relative;
    // display: flex;
    // justify-content: center;
    // align-items: center;

    // .imgBox {
    //     height: auto;
    //     width: 280px;
    //     //background-color: aqua;
    //     position: absolute;
    //     margin-top: 150px;

    //     img {
    //         width: 100%;
    //     }
    // }

    // }
    // }
  }

  .banner2 {
    min-height: 120px;

    h2 {
      color: $white-color;
      font-size: $smallHeading !important;
      text-transform: uppercase;
      font-weight: $bold;
    }
  }

  // .banner-style-3 {
  //     .wrapper {
  //         .row {
  //             .col-lg-6 {
  //                 margin-top: 175px;
  //             }
  //         }
  //     }
  // }

  .banner-style-4 {
    .wrapper {
      .col-bg-img {
        .col-1-content {
          justify-content: center;

          img {
            width: 150px;
            height: 150px;
            z-index: 2;
          }
        }
      }

      .banner4-content {
        align-items: center;
        justify-content: center;
        padding-top: 1.2rem;
      }

      p {
        margin-top: 8rem !important;
      }
    }
  }

  .banner-style-5 .row [class*="col-"] .text-wrapper {
    .text-content .banner-title {
      font-size: 40px;
      width: max-content;
    }

    .text-bg {
      width: 245px;
      height: 235px;
    }
  }

  .banner-style-6 .row [class*="col-"] .text-wrapper {
    .text-content .banner-title {
      font-size: 40px;
      width: auto;
    }

    .text-bg {
      width: 160px;
      height: 165px;
    }
  }
}

@media screen and (max-width: 575px) {
  .banner-style-4 {
    background-size: 100%;
    background-position: inherit;
    height: 171px;
  }
  .banner4-content {
    margin-top: 0px !important;
    h1 {
      font-size: 36px !important;
    }
  }
  img.ban-top-image {
    width: 350px;
    height: 260px;
    top: 515px !important;
  }
  h3.ban-top-text {
    font-size: 35px !important;
  }
  .banner-style-3 .wrapper .row .col-lg-6 h1 {
    margin-top: -110px;
  }
  .banner-style-5 .row [class*="col-"] {
    align-items: center;

    .text-wrapper {
      .text-bg {
        width: 205px;
        height: 185px;
        border-bottom-left-radius: 90px;

        .text-content {
          margin: 30px 0 0 50px;
        }
      }
    }
  }
  .banner-style-6 .row [class*="col-"] {
    align-items: left;

    .text-wrapper {
      .text-bg {
        width: 138px;
        height: 130px;
        border-bottom-right-radius: 90px;

        .text-content {
          margin: 30px 0 0 50px;
        }
      }
    }
  }

  .banner-style-5
    .row
    [class*="col-"]
    .banner-style-5
    .row
    [class*="col-"]
    .text-wrapper
    .banner-style-5
    .row
    [class*="col-"]
    .text-wrapper
    .text-content
    .banner-title {
    font-size: 30px;
    width: max-content;
  }

  .banner-style-6
    .row
    [class*="col-"]
    .banner-style-6
    .row
    [class*="col-"]
    .text-wrapper
    .banner-style-6
    .row
    [class*="col-"]
    .text-wrapper
    .text-content
    .banner-title {
    font-size: 30px;
    width: max-content;
  }

  .banner-style-5 .row [class*="col-"] .text-wrapper .text-bg {
    top: -20px;
  }

  .banner-style-6 .row [class*="col-"] .text-wrapper .text-bg {
    top: -35px;
  }
}

@media screen and (max-width: 425px) {
  img.ban-top-image {
    width: 350px;
    height: 260px;
    top: 515px !important;
  }

  .banner-style-4 {
    .wrapper {
      .col-bg-img {
        .col-1-content {
          justify-content: center;

          img {
            width: 100px;
            height: 100px;
            z-index: 2;
          }
        }
      }

      .banner4-content {
        margin-top: 0px !important;
        padding-top: 2rem;
        align-items: center;

        h1 {
          font-size: 1.5rem;
        }

        p {
          margin-top: 5rem;
        }
      }
    }
  }
}

@media screen and (max-width: 349px) {
  img.ban-top-image {
    width: 350px;
    height: 260px;
    top: 515px !important;
  }

  .banner-style-5 .row [class*="col-"] .text-wrapper .text-content {
    margin: 0 0 0 50px;
  }

  .banner-style-6 .row [class*="col-"] .text-wrapper .text-content {
    margin: 0 0 0 50px;
  }

  .banner-style-5
    .row
    [class*="col-"]
    .text-wrapper
    .text-content
    .banner-title {
    max-width: max-content;
    width: auto;
  }

  .banner-style-6
    .row
    [class*="col-"]
    .text-wrapper
    .text-content
    .banner-title {
    max-width: max-content;
    width: auto;
  }
}

@media screen and (max-width: 320px) {
  img.ban-top-image {
    width: 350px;
    height: 260px;
    top: 515px !important;
  }

  .banner-style-4 {
    .wrapper {
      .col-bg-img {
        .col-1-content {
          justify-content: space-around;

          img {
            width: 100px;
            height: 100px;
          }

          h1 {
            font-size: 24px;
          }
        }
      }

      .banner4-content {
        padding-top: 2rem;

        h1 {
          font-size: 23px;
        }

        p {
          margin-top: 2rem;
        }
      }
    }
  }
}

.blogs-banner {
//   font-size: 5px !important;
  text-transform: none !important;
}
