.blog-title-section {
  .container {
    min-height: 400px;
    //  background-color:bisque;
    width: 100%;

    padding: 20px 0px 0px;

    .wrapper1 {
      background-color: $white-color;
      padding: 50px 30px 50px;
    }

    .wrapper1 {
      display: flex;
      justify-content: center;
      flex-direction: column;
      min-height: 300px;

      .title {
        padding: 30px 0 50px 0;
        text-align: center;
        text-align-last: center;

        .blog-title-desc {
          text-align: center;
        }
      }

      .img-div {
        text-align: center;
      }

      .infographics {
        padding-top: 30px;
        width: 55%;
      }

      .bullet-title {
        font-weight: $semi-bold;
      }

      h1 {
        font-weight: bold;
        margin-bottom: 30px;
        color: #002496;
      }
      p {
        text-align: left;
        margin-top: 30px;
      }
      a {
        color: #0748bf;
        text-decoration: none;
        cursor: pointer;
      }
      a:hover {
        color: #6ad59c;
        text-decoration: none;
        cursor: pointer;
        transition: all 0.5s ease-in-out;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .blog-title-section {
    .section1 {
      padding: 0px 0px 0px;

      .wrapper1 {
        padding: 50px 30px 50px;
      }

      .wrapper1 {
        h1 {
          font-weight: $semi-bold;
          font-size: $mediumFont;
          line-height: 25px;
          margin-bottom: 20px;
          color: #002496;
        }
        p {
          font-size: $smallFont;
          text-align: left;
          margin-top: 30px;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .blog-title-section {
    .container {
      .wrapper1 {
        .title {
          padding: 30px 0 30px 0;
        }
        .infographics {
          padding-top: 20px;
          width: 100%;
        }
      }
    }
  }
}
