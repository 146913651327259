.our-team {

    .bg-img-team{
        position: absolute;
        bottom: 319px;
        width: 800px;
        height: 1000px;
    }

    .team-section {
        .container+.container {

            margin-top: 50px;

            .row {
                [class*="col-"] {
                    display: flex;
                    justify-content: center;
                    place-items: center;
                    margin-top: 30px;
                }
            }

            .row+.row {
                margin-top: 20px;
            }
        }

        .executives-row {
            width: 650px;
        }

        .team-row {
            width: 980px;


        }

        .row {
            .section-title {
                background: $greendark-gradient;
                color: $white-color;
                max-width: max-content;
                padding: 15px 60px;
                border-bottom-left-radius: 20px;
                border-bottom-right-radius: 20px;
                // border-top-right-radius: 5px;
                // border-top-left-radius: 5px;

                h1 {
                    font-size: 35px;
                    font-weight: 700;
                    margin-bottom: 0;
                    max-width: max-content;
                }
            }

            .card {
                border: none;
                background: transparent;

                .card-img-wrapper {
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .card-img-bg {
                        background: $background-gradient;
                        position: relative;
                    }

                    .card-img-top-wrapper {
                        position: absolute;
                        top: 0;

                        .card-img-top {
                            background-position: center;
                            background-repeat: no-repeat;
                            background-size: cover;
                        }

                        .nailah {
                            background-position: top;
                        }


                    }
                }

                .card-body {
                    margin-top: 30px;

                    .card-title {
                        font-weight: 700;
                    }

                    .card-title,
                    .card-subtitle {
                        font-size: 1rem;
                        text-align: center;
                    }
                }
            }

            .card-large {
                $card-width: 225px;
                width: $card-width;

                .card-img-bg {
                    width: $card-width;
                    height: 190px;
                    top: 20px;
                    border-radius: 50px;
                }

                .card-img-top-wrapper {
                    $card-img-top-wrapper-width: 200px;

                    width: $card-img-top-wrapper-width;

                    .card-img-top {
                        width: $card-img-top-wrapper-width;
                        height: 220px;
                        border-radius: 30px;
                    }

                }
            }

            .card-medium {
                $card-width: 180px;
                width: $card-width;

                .card-img-bg {
                    width: $card-width;
                    height: 150px;
                    top: 20px;
                    border-radius: 50px;
                }

                .card-img-top-wrapper {
                    $card-img-top-wrapper-width: 160px;

                    width: $card-img-top-wrapper-width;

                    .card-img-top {
                        width: $card-img-top-wrapper-width;
                        height: 180px;
                        border-radius: 30px;
                    }

                }

                .card-body {

                    .card-title,
                    .card-subtitle {
                        font-size: 14px;
                    }
                }
            }
        }

    }

    .section2 {
        margin: 50px 0 100px;
    }

    .text-with-bg-style-3 {
        position: relative;
        width: 910px;

        .text-bg {
            width: 565px;
            height: 223px;
            background: $background-gradient;
            border-radius: 40px;
        }

        .section-title {
            font-size: 65px;
            font-weight: 900;
            line-height: 1.4;
            margin: 20px 0 20px 30px;
            text-align: left;
            position: absolute;
            top: 0;

            span {
                color: $white-color;
            }
        }
    }
}


@media screen and (max-width: 1199px) {

    .our-team {

        .team-section {

            .container+.container {
                margin-top: 50px;

                .row+.row {
                    margin-top: 0px;
                }
            }

            .executives-row,
            .team-row {
                width: 100%;
            }
        }

        .text-with-bg-style-3 {
            width: 100%;
            display: flex;
            justify-content: center;

            .text-bg {
                width: 100%px;
                height: 180px;
            }

            .section-title {
                font-size: 50px;
                margin-left: 70px;
            }

        }
    }
}

@media screen and (max-width: 767px) {
    .bg-img-team{
        display: none;
    }
    .our-team .text-with-bg-style-3 {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 50px;

        .text-bg {
            height: 245px;
        }

    }
}

@media screen and (max-width: 575px) {
    .bg-img-team{
        display: none;
    }
    .our-team .text-with-bg-style-3 {
        .section-title {
            background: $background-gradient;
            border-radius: 40px;
            padding: 20px;
            font-size: 30px;
            position: static;
            margin-left: 0;
        }

        .text-bg {
            display: none;
        }
    }
}