.section-overlay{
    position: absolute;
    top: 0;
    left: 0;
    min-height: 100%;
    width: 100%;
    z-index: 100;
}
.mak_list{
    color: white;
}
.quest_mark{
    color: #46d07b;
    font-size: 30px !important;
    font-weight: 800 !important;
}
.mb-qmark{
    display: none;
}
.mb-top-margin{
    margin-top: 6rem;
}
.mb-bg-net{
    display: none;
}
@media (max-width: 500px) {
.find_solution{
    font-size: 18px !important;
    font-weight: 700;
}
.mak_responsive {
    text-align: left !important;
    font-size: 1rem;
    font-weight: normal;
}
// .mak_list{
//     text-decoration: underline;
//     text-underline-offset: 8px;
//     text-decoration-color: #46d07b;
// }
.mb-qmark-1{
    display: none !important;
}
.mb-qmark{
    display: inline;
}
.mb-clr-change{
    color: #46d07b;
}
.bb-circle-gradient{
    display: none;
}
.mb-top-margin {
    margin-top: 1rem;
}
.mb-bg-net{
    display: block;
    position: absolute;
    width: 230px;
    top: 65px;
    opacity: 0.2;
}
.mb-t5{
    margin-top: 90px;
}
.display-5{
    margin-top: 70px !important;
}
}