.slider
{
    background-color: antiquewhite;
    min-height: calc(100vh - 90px);
    // height: 800px;
    // max-height: 100vh;
    position: relative;
    width: 100%;
}


.carousel
{
    position: absolute !important;
    height: 100% !important;
    width: 100%;
}

.carousel-item,.active
{
    height:100%;

}

.carousel-item
{
    img{
        height: 100%;
        object-fit: cover;
    }
}

 .carousel-inner{
     height:100%;
 }


 .carousel-dots
 {
    width: 16px !important;
    height: 16px !important;
    margin: 0px 5px !important;
    border-radius: 50% !important;
    background-color: #b8b3b3 ;

 }

 .carousel-indicators
 {
    margin-bottom: 0.4rem !important;
}

 .carousel-caption
 {
     bottom: -0.5rem !important
 }


 .arrow-icon
 {
    background-image: none !important;
    bottom: 10.5%;
    position: absolute;
    // filter: brightness(0) invert(1);
    // background: $white-color;

    i{
        font-size: 20px;
        color: $black-color;
        background-color: $white-color;
        padding: 7px 12px;
        border-radius: 50%;
    }
 }



.carousel-description
{
    bottom: 0 !important;
    height: 100%;
    width: 100%;
right: 0 !important;
left: 0 !important;
padding-bottom: 0px !important;
padding-top: 0px !important;
    .wrapper
    {

        /* padding: 10px 0px; */
        min-height: 100%;
        background: linear-gradient(to right, rgba(75, 26, 122,0.85), rgba(0,0,0,0.7));
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 25px 25px;

        h2{
            text-transform: uppercase;
            font-size: $mediumHeading + 5;
            letter-spacing: 0.5px;
            word-spacing: 1px;

            .sp-1
            {
                font-weight: $bold;

                .sp-1-1
                {
                    color: $golden-color;
                }
            }
        }

        p{
            font-size: $largeFont1;
            margin-bottom: 30px;
            font-weight: $bold;
            letter-spacing: 1px;
            word-spacing: 1px;
            text-transform: uppercase;
        }

        .more-btn{

            text-decoration: none;
            color: $white-color;
            font-size: $regularFont;
            background-color: $black-color-2;
            padding: 8px 20px 8px 31px;
            margin-top: -10px;
        

            i{
                margin-left: 8px;
                transition: 0.3s ease-in-out;
            }

            &:hover{

                i{
                    margin-left: 13px;
                    transition: 0.3s ease-in-out;
                }
            }

        }
    }
}




@media only screen and (max-width: 2900px) and (min-width: 1600px)  {

.arrow-icon
 {
    background-image: none !important;
    bottom: 9% !important; 
    position: absolute;
    // filter: brightness(0) invert(1);
    // background: $white-color;

    i{
        font-size: 20px;
        color: $black-color;
        background-color: $white-color;
        padding: 7px 12px;
        border-radius: 50%;
    }
 }

}

 @media screen and (max-width: 768px) {

    .slider
    {
        min-height: 420px;
    }

    .carousel-dots
    {
       width: 14px !important;
       height: 14px !important;
       margin: 0px 3px !important;
       border-radius: 50% !important;
    }

    .arrow-icon
    {
        top: 80% !important;
      
       i{
           font-size: 16px;
           color: $black-color;
           background-color: $white-color;
           padding: 6px 10px;
           border-radius: 50%;
       }
    }
    


    .carousel-description
    {

        
        .wrapper
        {
            padding: 25px 80px !important;

            h2{
                font-size: $smallHeading;
            }

            p{
                font-size: $smallFont;
                margin-bottom: 20px;
            }

            .more-btn{

                font-size: $xsmallFont;
                padding: 9px 15px 9px 22px;
            }
        }

    }




 }


 @media screen and (max-width: 580px) {

    .slider
    {
        min-height: 320px;
    }
    
    .carousel-dots
    {
       width: 12px !important;
       height: 12px !important;
       margin: 0px 3px !important;
       border-radius: 50% !important;
    }

    .carousel-indicators
    {
       margin-bottom: 1rem !important;
    }

    .carousel-caption
    {
       // bottom: -1rem !important;

        h5{
            font-size: $xsmallFont;
        }
    }

    
    .arrow-icon
    {
        top: 78% !important;
      
       i{
           font-size: 15px;
           color: $black-color;
           background-color: $white-color;
           padding: 5px 9px;
           border-radius: 50%;
       }
    }



    .carousel-description
    {

        
        .wrapper
        {
            padding: 0px 60px !important;

            h2{
                font-size: $xsmallHeading;
            }

            p{
                font-size: $xsmallFont;
                margin-bottom: 20px;
            }

            .more-btn{

                font-size: $xsmallFont;
                padding: 7px 12px 7px 17px;
            }
        }

    }

 }