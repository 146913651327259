// .top-banner-content {
//     position: absolute;
//     right: -50px;
//     top: 650px;
//     // height: 600px;
//     width: 100%;
//     background-color: white;
//     padding: 70px;
//     border-bottom-left-radius: 30px;
//     border-top-left-radius: 30px;
// }
// @media only screen and (min-width: 1024px) {
//     .top-banner-content {
//         position: absolute;
//         right: -px;
//         top: 650px;
//         // height: 600px;
//         width: 100%;
//         background-color: white;
//         padding: 70px;
//         border-bottom-left-radius: 30px;
//         border-top-left-radius: 30px;
//     }
// }
// @media only screen and (min-width: 1228px) {
//     .top-banner-content {
//         position: absolute;
//         right: -38px;
//         top: 80px;
//         left: 550px;
//         height: 600px;
//         background-color: white;
//         padding: 70px;
//         border-bottom-left-radius: 30px;
//         border-top-left-radius: 30px;
//     }
// }
// @media only screen and (min-width: 1396px) {
//     .top-banner-content {
//         position: absolute;
//         right: -121px;
//         top: 80px;
//         left: 550px;
//         height: 560px;
//         background-color: white;
//         padding: 70px;
//         border-bottom-left-radius: 30px;
//         border-top-left-radius: 30px;
//     }
// }
@media only screen and (min-width: 1536px) {
    .top-banner-content {
        position: absolute;
        right: -100px;
        top: 80px;
        left: 550px;
        height: 500px;
        background-color: white;
        padding: 70px;
        border-bottom-left-radius: 30px;
        border-top-left-radius: 30px;
    }
}
@media only screen and (min-width: 1700px) {
    .top-banner-content {
        position: absolute;
        right: -185px;
        top: 80px;
        left: 550px;
        height: 500px;
        background-color: white;
        padding: 70px;
        border-bottom-left-radius: 30px;
        border-top-left-radius: 30px;
    }
}
@media only screen and (min-width: 1920px) {
    .top-banner-content {
        position: absolute;
        right: -290px;
        top: 80px;
        left: 550px;
        height: 500px;
        background-color: white;
        padding: 70px;
        border-bottom-left-radius: 30px;
        border-top-left-radius: 30px;
    }
}
@media only screen and (min-width: 2048px) {
    .top-banner-content {
        position: absolute;
        right: -300px;
        top: 80px;
        left: 550px;
        height: 500px;
        background-color: white;
        padding: 70px;
        border-bottom-left-radius: 30px;
        border-top-left-radius: 30px;
    }
}


.howItWorks {

    section {
        overflow: hidden;
    }
    .text-with-bg-style-2{
        height: 680px;
        background-position: center;
        background-repeat: no-repeat;
    }

    .text-with-bg-style-2 {

        // position: relative;
        // margin-bottom: 27rem;
        // overflow: visible;

        // .wrapper:first-child {
        //     min-height: 1150px;
        //     width: 100%;
        //     background-blend-mode: overlay;
            // background-color: #09182DD4;
        //     background-repeat: no-repeat;
        //     background-size: cover;
        // }

        .wrapper+.wrapper {
            margin-top: 3rem;

        }

        .wrapper {

            .row {
                position: relative;
            }

            .heading-col {
                // position: absolute;
                // top: 70px;
                margin-top: 70px;
                width: max-content;
            }

            .text-col {
                position: absolute;
                right: 0;
            }

            h1 {
                color: $white-color;
                font-size: 5.5rem;
                font-weight: 700;
            }

            h1:first-child {
                color: $white-color;
            }

            p {
                font-size: 1.1rem;
                font-weight: $bold;
            }

            .img-wrapper {

                img {
                    position: relative;
                    top: 50px;
                    left: 90px;
                }
            }

        }
    }

    .section-2 {

        margin-top: 1rem;
    }

    .section-3 {
        padding-top: 100px;
        padding-bottom: 115px;

        video {
            width: 100%;
            height: auto;
            border-radius: 20px;
        }
    }

    .section-4 {

        margin-top: 3rem;
    }

    .section-5 {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        margin-top: -90px;
        padding: 150px 0 180px;
    }

    .title-with-text-style-1 {

        .card {
            margin-top: 4rem;
            border: 2px solid $green-color;

            .card-body {

                padding: 16px 30px;

                .card-title-wrapper {

                    display: flex;
                    justify-content: center;
                    position: relative;
                    top: -53px;

                    .card {
                        margin-top: 0;
                        background: $green-color;
                        color: $white-color;
                        width: max-content;
                        border-radius: 15px;

                        .card-body {

                            padding: 20px 80px;

                            .card-title {

                                font-size: 1.5rem;
                                margin: 0;
                            }
                        }
                    }
                }

                ul {
                    list-style: none;
                    // padding: 0;
                    line-height: 2;
                    text-align: left;

                    li {
                        display: flex;

                        p {
                            margin-bottom: 0;
                            width: 100%;
                        }

                        b {
                            margin-right: 2px;
                        }
                    }

                    li:before {
                        background: $green-color;
                        border-radius: 50%;
                        content: "";
                        display: block;
                        width: 10px;
                        height: 10px;
                        margin-right: 10px;
                        position: relative;
                        top: 10px;
                    }
                }
            }
        }
        .card:hover{
            background: $dark-green !important;
            transition: all 0.5s ease-in-out;
        }
    }

    .three-col--img-with-text-style-3 {
        background-size: 1400px 1430px;
        background-repeat: no-repeat;
        background-position-x: left;
        background-position-y: bottom;
        // background-position: center;
        // background-repeat: no-repeat;
        // background-size: 575px;

        .container:first-child {
            .row {
                position: relative;

                // .watermark {
                //     margin: 0 auto;
                //     width: 600px;
                //     position: absolute;
                //     top: 60%;
                //     left: 50%;
                //     transform: translate(-50%, -50%);
                //     opacity: 0.3;
                // }
            }
        }

        .container+.container {
            margin-top: 6rem;
            margin-bottom: 6rem;
        }

        .row {

            .section-title {
                font-weight: $semi-bold;
            }

            hr {
                background-color: $green-color;
                height: 2px;
                opacity: 1;
            }

            .d-grid {
                place-items: center;
                margin-top: 3rem;
            }
            .c-style{
                background: #efefef !important;
                color: #000 !important;
            }

            .card {
                width: 100%;
                height: 100%;
                background: $dark-green;
                border-radius: 20px;
                color: $white-color;
                place-items: center;
                text-align: center;
                border: 1px solid #46D07B;

                .card-img-wrapper {
                    display: grid;
                    place-items: center;
                    height: 110px;
                    margin-top: 25px;
                }

                .card-img-top {

                    width: 80px;
                }

                .card-body {

                    .card-title {

                        font-size: 2rem;
                        font-weight: $semi-bold;
                    }
                }
            }

            .card:hover {
                background: $green-color;
                transition: all 0.5s ease-in-out;
            }
        }
    }

    // padding-bottom: 300px;
    .section1 {

        min-height: 200px;
        background-color: $white-color;
        width: 100%;
        padding: 35px 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        p {
            color: $black-color;
            padding: 10px;
            text-align: center;
            font-size: $regularFont;
            font-weight: $medium;
        }
    }

    .section3 {

        min-height: 200px;
        background-color: $black-color-2;
        width: 100%;
        margin: 0px 0px 30px;
        padding: 50px 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: -8px;

        h4 {
            color: $white-color;
            font-weight: 600;
            padding-bottom: 20px;
        }

        .content {
            color: $white-color;
            font-size: $regularFont;
            //font-weight: $medium;



            .tick {


                margin-right: 13px;

                i {
                    font-size: 18px;

                }
            }

            .description {
                font-weight: $thin;

                // display: flex;
                // align-items: center;
                b {
                    font-weight: $semi-bold;
                }
            }
        }
    }

    .section4 {

        min-height: 200px;
        // background-color:bisque;
        width: 100%;
        padding: 0px 0px;

        .nav-request {
            background-color: $black-color;
            color: $white-color !important;
            font-weight: $regular !important;
            padding: 12px 20px;
            border-radius: 30px;
            font-size: 16px !important;
            text-decoration: none !important;

        }

        .container-fluid {
            overflow-x: hidden !important;
        }

        h2 {
            font-weight: $semi-bold;
            text-transform: uppercase;
            margin-bottom: 30px;
            margin-top: 10px;
        }

        .steps {
            min-height: 200px;
            background-color: $black-color-2;
            padding: 110px 40px 35px !important;
            background-size: fixed;
            background-attachment: fixed;
            background-color: $black-color-2;
            background-repeat: no-repeat;
            background-position: center;

            .box {

                // border: 1px solid black;
                display: flex;
                flex-direction: column;
                margin-top: -70px;
                padding: 60px;

                .img-block {
                    min-width: 150px;
                    // min-height: 100px;
                    height: auto;
                    // background-color: aqua;

                    img {
                        width: 100%;
                        height: auto;
                    }
                }

                .img-content {
                    min-width: 150px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    padding: 30px 0px 0px;

                    h5 {
                        font-size: $mediumFont;
                        font-weight: $heavy-bold;
                        letter-spacing: 0.5px;
                        text-transform: uppercase;
                        color: $white-color;
                    }

                    p {
                        text-align: center;
                        line-height: 20px;
                        color: $white-color;
                    }
                }
            }

        }

    }

}


/************************** 
***** Responsiveness ******
**************************/
@media only screen and (max-width: 2000px) and (min-width: 1700px) {

    .section4 {


        .steps {

            .box {

                padding: 80px !important;

            }
        }
    }

}

@media only screen and (max-width: 2900px) and (min-width: 1700px) {
    
    .text-with-bg-style-2{
        height: 1100px;
        background-position: center;
        background-repeat: no-repeat;
    }

    .section4 {


        .steps {

            .box {

                padding: 90px !important;

                .img-content {
                    h5 {
                        font-size: $mediumFont;
                        font-weight: $heavy-bold;
                        letter-spacing: 0.5px;
                        text-transform: uppercase;
                        color: $white-color;
                    }

                    p {
                        text-align: center;
                        line-height: 20px;
                        font-size: $mediumFont;
                        color: $white-color;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1250px) and (min-width: 990px) {

    .howItWorks {

        .text-with-bg-style-2{
            height: 1100px;
            background-position: center;
            background-repeat: no-repeat;
        }
        .section4 {

            .steps {

                .box {

                    .img-content {
                        p {
                            padding: 0px 20px !important;
                        }
                    }

                }

            }

        }
    }
}

@media screen and (max-width: 1199px) {
    .howItWorks {

        .text-with-bg-style-2 {

            margin-bottom: 21rem;


            .wrapper+.wrapper {

                margin-top: 28rem;
            }

            .wrapper {

                .row {
                    display: flex;
                    justify-content: center;
                }

                .text-col {

                    right: auto;
                }

                .img-wrapper {

                    margin-top: 12px;
                    text-align: center;

                    img {
                        position: static;
                    }
                }
            }
        }

        .section-4 {
            margin-top: 3rem;
        }

        .section-2 {
            margin-top: 3rem;
        }

        // .three-col--img-with-text-style-3 {

        //     .container+.container {
        //         margin-top: 3rem;
        //         margin-bottom: 3rem;
        //     }

        //     .row {

        //         .card {
        //             width: 220px;

        //             .card-body {

        //                 .card-title {
        //                     font-size: 1.25rem;
        //                 }
        //             }
        //         }
        //     }
        // }

    }
}

@media screen and (max-width: 991px) {

    .howItWorks {
        .text-with-bg-style-2 {

            margin-bottom: 25rem;
        }
    }

}

@media screen and (max-width: 767px) {

    .howItWorks {

        .text-with-bg-style-2 {
            margin-bottom: 29rem;
        }

        .section1 {

            padding: 30px 35px 20px;

            p {
                padding: 0px;
                text-align: center;
                font-size: $smallFont !important;
            }
        }

        .section3 {

            min-height: 200px;
            width: 100%;
            margin: -17px 0px 30px;
            padding: 50px 15px !important;

            .content {
                color: $white-color;
                font-size: $smallFont;
            }

        }


        .section4 {

            h2 {
                font-size: $smallHeading - 2;
                padding: 0px 15px;
            }

            .steps {

                .box {
                    margin-top: 0px !important;
                    padding: 10px;

                }

            }

        }

        .section-5 {
            padding: 150px 0 250px;
        }

    }

}

@media screen and (max-width: 575px) {
    .howItWorks {
        .top-banner-content{
            top:600px;
            right: 0px;
            height: 1150px;
            width: 100%;
            background-color: white;
            padding: 70px;
            border-bottom-left-radius: 30px;
            border-top-left-radius: 30px;
        }
        .text-with-bg-style-2 {

            margin-bottom: 0;
            height: 1650px;

            .wrapper:first-child {
                min-height: 280px;
                height: 280px;
            }

            .wrapper {
                h1 {
                    font-size: 3rem;
                }

                h1:first-child {
                    margin-top: 105px;
                }

                .heading-col {
                    position: static;
                }

                .text-col {
                    position: static;
                }
            }

            .wrapper+.wrapper {
                margin-top: 330px;
            }
        }

        .section-3 {
            margin-top: 3rem;
        }

        .title-with-text-style-1 .card .card-body .card-title-wrapper .card .card-body {
            padding: 20px;

            .card-title {

                font-size: 1rem;
                font-weight: $bold;
            }
        }
    }
}

@media screen and (max-width: 390px) {

    .howItWorks {
        .section3 {
            margin: -25px 0px 30px !important;
        }
    }
}