.privacyPolicy
{
    .section1
    {
        min-height: 400px;
        //  background-color:bisque;
          width: 100%;

          padding: 20px 0px 0px;
         
        .wrapper1
        {
            background-color: $white-color;
            padding: 50px 120px 50px;
        }

        .wrapper2
        {
            background-color: $black-color-2;
            color: $white-color;
            padding: 60px 120px 50px;
        }

        .wrapper1,.wrapper2
        {
        
        display: flex;
        justify-content: center;
        flex-direction: column;
        min-height: 300px;

            h5{
                font-weight: $semi-bold;
                margin-bottom: 30px;
            }
            p
            {
                text-align: justify;
            }
        }

        .wrapper3
        {
            //background-color:antiquewhite;
            background-repeat: no-repeat;
            background-size: contain;
            background-attachment: fixed;
            background-position: center;
            min-height: 400px;
            padding: 80px 20px 120px;

            .boxWrapper
            {
                display: flex;
                justify-content: space-evenly;
                align-items: center;
                min-height: 400px;

                .box{
                    min-height: 385px;
                    max-height: 450px;
                    // min-width: 100px;
                    // max-width: 350px;
                    width: 350px;
                    padding: 25px;
                    background-color: $black-color-2;
                    border: 5px solid $mid-purple-color;
                    color: $white-color;

                    h5{
                        font-weight: $semi-bold;
                        margin-bottom: 20px;
                    }
                    p
                    {
                        text-align: justify;
                       
                    }
                }

            }


           
        }
    }
}


@media screen and (max-width: 768px) {

.privacyPolicy
{
    .section1
    {
        padding: 0px 0px 0px;
         
        .wrapper1
        {
        
            padding: 50px 35px 30px;
        }

        .wrapper2
        {
            padding: 50px 35px 40px;
        }

        .wrapper1,.wrapper2
        {

            h5{
                font-weight: $semi-bold;
                font-size: $mediumFont;
                line-height: 25px;
                margin-bottom: 20px;
            }
            p
            {
                font-size: $smallFont;
                text-align: justify;
            }
        }

        .wrapper3
        {
            
            padding: 80px 40px 40px;

            .boxWrapper
            {

                .box{
                    min-height: 300px;
                    max-height: 340px;
                    min-width: 100px;
                    max-width: 350px;
                    padding: 25px;
                    margin-bottom: 50px;
                    background-color: $black-color-2;
                    border: 4px solid $mid-purple-color;
                    color: $white-color;

                  
                    h5{
                        font-weight: $semi-bold;
                        font-size: $mediumFont;
                        line-height: 25px;
                        margin-bottom: 20px;
                    }
                    p
                    {
                        font-size: $smallFont;
                        text-align: justify;
                    }
                }

            }


           
        }
    }
}


}