.sensor-2 {

    .main-wrapper {
        position: relative;
        margin: auto;
        width: max-content;

        img {
            position: relative;
            top: 65px;
            width: 600px;
        }

        [class*="text"] {
            color: #fff;
            display: flex;
            flex-direction: column;
            align-items: center;
            position: absolute;

            p {
                font-size: 1rem;
                position: relative;
            }

            .line-wrapper {
                width: max-content;

                .line {
                    background-color: #fff;
                    height: 120px;
                    width: 1px;
                    position: relative;
                    animation-duration: 5s;

                    .dot {
                        background-color: #fff;
                        border-radius: 50%;
                        height: 15px;
                        width: 15px;
                        position: absolute;
                        left: -7px;
                        top: -5px;
                    }
                }
            }
        }

        .text-1 {
            top: -30px;
            left: 140px;

            p {
                left: 70px;
            }

            .line-wrapper {
                transform: rotate(20deg);

                @keyframes linedrop-text-1 {
                    from {
                        height: 0;
                    }

                    to {
                        height: 125px;
                    }
                }

                .line {
                    animation-name: linedrop-text-1;
                    height: 125px;
                }
            }
        }

        .text-2 {
            top: 30px;
            left: 330px;

            p {
                left: 70px;
            }

            .line-wrapper {
                transform: rotate(20deg);

                @keyframes linedrop-text-2 {
                    from {
                        height: 0;
                    }

                    to {
                        height: 130px;
                    }
                }

                .line {
                    animation-name: linedrop-text-2;
                    height: 130px;
                }
            }
        }

        .text-3 {
            top: 12px;
            left: 443px;

            p {
                top: 50px;
                left: 165px;
                margin-bottom: 0;
            }

            .line-wrapper {
                transform: rotate(50deg);

                @keyframes linedrop-text-3 {
                    from {
                        height: 0;
                    }

                    to {
                        height: 235px;
                    }
                }

                .line {
                    animation-name: linedrop-text-3;
                    height: 235px;
                }
            }
        }

        .text-4 {
            top: 172px;
            right: -50px;

            p {
                top: 68px;
                left: 120px;
                margin-bottom: 0;
            }

            .line-wrapper {
                transform: rotate(90deg);

                @keyframes linedrop-text-4 {
                    from {
                        height: 0;
                    }

                    to {
                        height: 115px;
                    }
                }

                .line {
                    animation-name: linedrop-text-4;
                    height: 115px;
                }
            }
        }

        .text-5 {
            top: 275px;
            right: -40px;

            p {
                top: 68px;
                left: 125px;
                margin-bottom: 0;
            }

            .line-wrapper {
                transform: rotate(90deg);

                @keyframes linedrop-text-5 {
                    from {
                        height: 0;
                    }

                    to {
                        height: 115px;
                    }
                }

                .line {
                    animation-name: linedrop-text-5;
                    height: 115px;
                }
            }
        }

        .text-6 {
            bottom: -65px;
            right: 235px;

            p {
                bottom: -185px;
                left: -20px;
                margin-bottom: 0;
            }

            .line-wrapper {
                transform: rotate(200deg);

                @keyframes linedrop-text-6 {
                    from {
                        height: 0;
                    }

                    to {
                        height: 150px;
                    }
                }

                .line {
                    animation-name: linedrop-text-6;
                    height: 150px;
                }
            }
        }

        .text-7 {
            top: 30px;
            left: -37px;

            p {
                top: 100px;
                left: -215px;
                margin-bottom: 0;
            }

            .line-wrapper {
                transform: rotate(-90deg);

                @keyframes linedrop-text-7 {
                    from {
                        height: 0;
                    }

                    to {
                        height: 245px;
                    }
                }

                .line {
                    animation-name: linedrop-text-7;
                    height: 245px;
                }
            }
        }

        .text-8 {
            top: 170px;
            left: -15px;

            p {
                top: 225px;
                left: -235px;
                margin-bottom: 0;
            }

            .line-wrapper {
                transform: rotate(-120deg);

                @keyframes linedrop-text-8 {
                    from {
                        height: 0;
                    }

                    to {
                        height: 215px;
                    }
                }

                .line {
                    animation-name: linedrop-text-8;
                    height: 215px;
                }
            }
        }

    }
}

@media screen and (max-width: 1199px) {
    .sensor-2 {

        .main-wrapper {

            .text-1 {
                top: -46px;
                left: 60px;

                p {
                    left: -40px;
                }

                .line-wrapper {
                    transform: rotate(-10deg);
                }
            }

            .text-2 {
                top: 15px;
                left: 270px;

                p {
                    left: -40px;
                }

                .line-wrapper {
                    transform: rotate(-10deg);
                }
            }

            .text-3 {
                top: -20px;
                left: 395px;

                p {
                    top: -10px;
                    left: 45px;
                    margin-bottom: 0;
                }

                .line-wrapper {
                    transform: rotate(20deg);

                    @keyframes linedrop-text-3 {
                        from {
                            height: 0;
                        }

                        to {
                            height: 235px;
                        }
                    }

                    .line {
                        animation-name: linedrop-text-3;
                        height: 235px;
                    }
                }
            }

            .text-4 {
                top: 214px;
                right: 0px;

                p {
                    top: 145px;
                    left: 60px;
                    margin-bottom: 0;
                }

                .line-wrapper {
                    transform: rotate(140deg);

                    @keyframes linedrop-text-4 {
                        from {
                            height: 0;
                        }

                        to {
                            height: 115px;
                        }
                    }

                    .line {
                        animation-name: linedrop-text-4;
                        height: 115px;
                    }
                }
            }

            .text-5 {
                top: 340px;
                right: 100px;

                p {
                    top: 195px;
                    left: 5px;
                }

                .line-wrapper {
                    transform: rotate(180deg);

                    @keyframes linedrop-text-5 {
                        from {
                            height: 0;
                        }

                        to {
                            height: 155px;
                        }
                    }

                    .line {
                        animation-name: linedrop-text-5;
                        height: 155px;
                    }
                }
            }

            .text-9 {
                top: 165px;
                left: 110px;

                p {
                    top: 300px;
                    left: -180px;
                }

                .line-wrapper {
                    transform: rotate(-160deg);

                    @keyframes linedrop-text-9 {
                        from {
                            height: 0;
                        }

                        to {
                            height: 230px;
                        }
                    }

                    .line {
                        animation-name: linedrop-text-9;
                        height: 230px;
                    }
                }
            }

        }
    }
}

@media screen and (max-width: 767px) {
    .sensor-2 {

        .main-wrapper {


            img {
                width: 290px;
            }

            [class*="text"] {

                p {
                    font-size: 12px !important;
                }

                .line-wrapper .line .dot {
                    height: 8px;
                    width: 8px;
                    left: -4px;
                }
            }

            .text-1 {
                top: -30px;
                left: 10px;

                p {
                    left: 0;
                }

                .line-wrapper {
                    transform: rotate(0deg);

                    @keyframes linedrop-text-1 {
                        from {
                            height: 0;
                        }

                        to {
                            height: 80px;
                        }
                    }

                    .line {
                        animation-name: linedrop-text-1;
                        height: 80px;
                    }
                }
            }

            .text-2 {
                top: 30px;
                left: 125px;

                p {
                    left: -30px;
                }

                .line-wrapper {
                    transform: rotate(0deg);

                    @keyframes linedrop-text-2 {
                        from {
                            height: 0;
                        }

                        to {
                            height: 60px;
                        }
                    }

                    .line {
                        animation-name: linedrop-text-2;
                        height: 60px;
                    }
                }
            }

            .text-3 {
                top: -30px;
                left: 160px;

                p {
                    top: -15px;
                    left: 0;
                }

                .line-wrapper {
                    transform: rotate(0deg);

                    @keyframes linedrop-text-3 {
                        from {
                            height: 0;
                        }

                        to {
                            height: 160px;
                        }
                    }

                    .line {
                        animation-name: linedrop-text-3;
                        height: 160px;
                    }
                }
            }

            .text-4 {
                top: 65px;
                right: 5px;

                p {
                    top: -15px;
                    left: 12px;
                }

                .line-wrapper {
                    transform: rotate(0deg);

                    @keyframes linedrop-text-4 {
                        from {
                            height: 0;
                        }

                        to {
                            height: 68px;
                        }
                    }

                    .line {
                        animation-name: linedrop-text-4;
                        height: 68px;
                    }
                }
            }

            .text-5 {
                top: 196px;
                right: 0px;

                p {
                    top: 95px;
                    left: 5px;
                }

                .line-wrapper {
                    transform: rotate(167deg);

                    @keyframes linedrop-text-5 {
                        from {
                            height: 0;
                        }

                        to {
                            height: 65px;
                        }
                    }

                    .line {
                        animation-name: linedrop-text-5;
                        height: 65px;
                    }
                }
            }

            .text-6 {
                bottom: -148px;
                right: 45px;

                p {
                    bottom: -185px;
                    left: 10px;
                }

                .line-wrapper {
                    transform: rotate(170deg);

                    @keyframes linedrop-text-6 {
                        from {
                            height: 0;
                        }

                        to {
                            height: 150px;
                        }
                    }

                    .line {
                        animation-name: linedrop-text-6;
                        height: 150px;
                    }
                }
            }

            .text-9 {
                top: 95px;
                left: 50px;

                p {
                    top: 170px;
                    left: -50px;
                }

                .line-wrapper {
                    transform: rotate(-160deg);

                    @keyframes linedrop-text-9 {
                        from {
                            height: 0;
                        }

                        to {
                            height: 105px;
                        }
                    }

                    .line {
                        animation-name: linedrop-text-9;
                        height: 105px;
                    }
                }
            }

        }
    }
}