.footer {
    background: $background-color1;
    // padding-top: 5px;
    min-height: 260px;
    width: 100%;
    // background-color: $background-color1;


    hr:not([size]) {
        height: 5px;
    }

    hr {
        background: #BEBEBE;
        opacity: 1;
        margin: 0;
    }

    .border-wrap {
        // background: $background-color1;
        padding: 60px 0 25px 0;
    }

    .row+.row {
        margin-top: 40px;
    }

    .sec-1 {
        //background-color: brown;

        .wrapper {
            // display: flex;
            // flex-direction: column;
            // justify-content: center;
            // align-items: center;

            p {
                font-weight: $bold;

                color: $blue-color;
                letter-spacing: 0.5px;
            }

            ul {
                padding-left: 0;
                margin-top: -4px;

                li {
                    list-style-type: none;
                    padding-bottom: 10px;

                    a {
                        text-decoration: none;
                        color: $blue-color;
                        font-weight: $regular;
                        font-size: $smallFont;
                    }
                }
            }
        }
    }


    .sec-2 {
        //background-color: rgb(110, 199, 59);
        display: flex;
        // align-items: flex-end;
        flex-direction: column;
        //padding: 0px 30px !important;

        .sec-2-wrapper {


            .imgBox {

                min-width: 120px;
                max-width: 290px;
                display: flex;
                justify-content: flex-start;
                margin-bottom: -5px;
                margin-top: -20px;

                img {
                    width: 100%;
                    height: auto;
                    opacity: 1;
                    margin-left: -10px;
                }
            }

            .social-list {
                margin-top: 25px;

                i {
                    font-size: $smallHeading + 1;
                    padding-right: 15px;
                    color: $black-color-3;
                }
            }

            form {

                .form-control {
                    background-color: $blue-color;
                    border-radius: 10px;
                    color: $white-color;
                }
            }

        }
    }
}



@media only screen and (max-width: 2900px) and (min-width: 1600px) {

    .footer {
        .sec-2 {


            .sec-2-wrapper {

                .imgBox {

                    min-width: 120px;
                    max-width: 350px;
                    margin-top: -35px;
                }


            }
        }
    }


}





@media screen and (max-width: 768px) {

    .footer {
        .border-wrap {
            padding-bottom: 60px;
        }

        // .sec-1
        // {
        //     .wrapper
        //     {
        //         align-items: flex-start ;
        //     }
        // }

        .sec-2 {
            padding: 0px 10px !important;
            justify-content: center;
            align-items: flex-start !important;

            .sec-2-wrapper {
                padding-left: 0px !important;

                p {
                    margin-top: 20px;
                }

                .imgBox {

                    min-width: 120px;
                    max-width: 220px;


                    img {
                        margin-top: 10px;
                        margin-left: -8px !important;
                    }
                }

                .social-list {
                    margin-top: 20px;

                    i {
                        font-size: $smallHeading - 2 !important;
                        padding-right: 15px;
                        color: $black-color-3;
                    }
                }
            }

        }


    }

}