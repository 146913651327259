// Section 1 Styling

.products-main-card {
  background-color: transparent !important;
  padding: 50px;
  border: none;
  border-radius: 15px 15px 15px 15px;
  max-height: 100% !important;

  .product-card-img {
    width: 100%;
    border-radius: 15px;
    height: auto;
    color: white;
  }

  .product-card-title {
    text-align: center;
    color: black;
    font-weight: 700;
  }

  .product-card-text {
    text-align: center;
    color: black;
    font-weight: 500;

    .card-text-span {
      font-weight: bold;
    }
  }
}

@media (max-width: 1199px) {
  .products-main-card {
    margin-top: 5%;
    margin-bottom: 5%;
    padding: 0 3% 0 3% !important;
    .product-card-body {
      .product-card-text {
        p {
          text-align: justify;
        }
      }
    }
  }
}

// Section 1 Styling ends here

// Section 2 Styling

.blog-9-section-2 {
  .container {
    overflow-x: hidden;
    margin-bottom: 3%;

    .row {
      .blog-9-box-1 {
        h2 {
          color: #081c52;
          font-weight: 600;
        }
        p {
          color: black;
          font-weight: 500;
        }
        .product-link {
          display: flex;
          .prdct-lnk-text {
            text-transform: capitalize;
            font-weight: 600;
            font-size: medium;
            color: #081c52;
            padding-top: 2%;
          }
          .link-btn {
            padding-left: 25px !important;
            .product-btn {
              text-transform: capitalize;
              padding: 8px 15px 0px 15px !important;
              text-decoration: none;
              background-color: #081c52;
              color: white;
              font-size: small;
              font-weight: 600;
              padding: 8px 25px 8px 25px !important;
              border-radius: 30px;
            }
          }
        }
        .prdct-price {
          text-transform: capitalize;
          font-weight: 600;
          font-size: large;
          color: #3a8d5a;
        }
      }
      .blog-9-box-2 {
        h2 {
          color: #081c52;
          font-weight: 600;
        }
        p {
          color: black;
          font-weight: 500;
        }
        .product-link {
          display: flex;
          .prdct-lnk-text {
            text-transform: capitalize;
            font-weight: 600;
            font-size: medium;
            color: #081c52;
            padding-top: 2%;
          }
          .product-btn {
            text-transform: capitalize;
            padding: 8px 15px 0px 15px !important;
            text-decoration: none;
            background-color: #081c52;
            color: white;
            font-size: small;
            font-weight: 600;
            padding: 8px 25px 8px 25px !important;
            border-radius: 30px;
          }
        }
        .prdct-price {
          text-transform: capitalize;
          font-weight: 600;
          font-size: large;
          color: #3a8d5a;
        }
      }
      .img-box {
        .blog-9-bb-image {
          width: 100%;
          border-radius: 15px;
          margin-top: 10%;
        }
      }
    }
  }
}

@media (min-width: 1200px) {
  .blog-9-section-2 {
    .container {
      .row {
        .blog-9-box-1 {
          margin-top: 10%;
        }
      }
    }
  }
}

@media (max-width: 1199px) {
  .blog-9-section-2 {
    .container {
      margin-bottom: 5% !important;
      h2 {
        text-align: center;
      }
      p {
        text-align: justify;
      }
      .blog-9-bb-image {
        margin-top: 0% !important;
      }
    }
  }
}

@media (max-width: 575px) {
  .blog-9-section-2 {
    .container {
      padding: 0 6% 0 6% !important;
    }
  }
}

// Section 2 styling ends here

// Section 3 Styling

.prdct-bottom-text {
  background-color: transparent !important;
  padding: 0px 50px 50px 50px;
  border: none;
  margin-top: 6% !important;

  .bottom-text {
    text-align: center;
    color: black;
    font-weight: 500;
  }
}

@media (max-width: 1199px) {
  .prdct-bottom-text {
    padding: 5% 6% 5% 6% !important;
    margin-top: 5% !important;
    .bottom-text-body {
      .bottom-text {
        p {
          margin-top: 5% !important;
          text-align: justify !important;
        }
      }
    }
  }
}
// Section 3 styling ends here
