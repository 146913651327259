.contact {

    .banner-style-3 .wrapper .row .col-lg-6 {
        margin-top: 181px;
    }

    .section1 {

        width: 100%;
        margin: 6rem 0;

        .wrapper1 {

            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-left: 30px;
            //   background-color: aquamarine;

            hr {
                margin-top: 0px !important;
            }

            h6 {
                font-weight: $semi-bold;
                //padding-bottom: 40px;
                font-size: $largeFont + 1;
                text-transform: uppercase;
            }

            p {
                line-height: 20px;
                padding-right: 30px;
                font-size: $mediumFont + 1;
            }
        }

    }

}



@media screen and (max-width: 1199px) {
    .contact {
        .section1 {
            margin: 3rem 0;

            .wrapper1 {



                h6 {
                    font-weight: $semi-bold;
                    font-size: $regularFont;
                }

                p {
                    line-height: 18px;
                    font-weight: $bold;
                    padding-right: 0px !important;
                    text-align: justify;
                    font-size: $smallFont + 1;
                }
            }

        }
    }
}