$background-color1: #efefef;
$background-color2: rgb(156, 153, 153);

$black-color: #272727;
$black-color-2: #161616;
$black-color-3: #919191;

$lightblue-color: #1180ff;
$green-color: #46d07b;
$dark-green: #08335d;
$blue-color: #09182d;
$blue-color-2: #222869;
$blue-color-3: #002395;
$tranparent-bg: #ffffff00;

$golden-color: #cc861e;

$grey-color: rgb(115, 133, 133);
$darkgrey-color: #3d3d3d;

$light-purple-color: #712bb6;
$mid-purple-color: #561993;
$purple-color: #2e0853;

$white-color: #fff;

$background-gradient: linear-gradient(
  0deg,
  $lightblue-color 0%,
  $blue-color-3 100%
);
$greendark-gradient: linear-gradient(0deg, $dark-green 0%, $green-color 100%);

$headerLinkFont: 16.5px;
$xsmallFont: 12px;
$smallFont: 14px;
$regularFont: 16px;
$regularFont-1: 17px;
$mediumFont: 18px;

$largeFont: 19px;
$largeFont1: 22px;

$heading1: 18px;
$heading2: 20px;

$xsmallHeading: 21px;
$smallHeading: 23px;
$mediumHeading: 31px;
$largeHeading: 41px;

$thin: 300;
$regular: 400;
$medium: 500;
$bold: 600;
$semi-bold: 700;
$heavy-bold: 800;
$heavy-bold-2: 900;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Rubik", sans-serif;
}

::selection {
  background-color: $green-color;
  color: $blue-color-3;
}

body {
  overflow-x: hidden;
}

.body {
  min-height: 100vh;
  background-color: $white-color;
  position: relative;
}

section {
  background-color: $white-color;
}

p {
  font-size: 1rem;
}

.wrap {
  background-color: $white-color;
  overflow-x: hidden !important;
}

.wrap-overflow-hide {
  overflow-y: hidden !important;
  height: 0px !important;
}

.btn-primary {
  background: $lightblue-color;
  color: $white-color;
  font-size: $regularFont;
  padding: 4px 40px;
  border-radius: 20px;
}

.btn-primary:hover {
  background: $lightblue-color;
}

.btn-primary-2 {
  background-image: linear-gradient($lightblue-color, $blue-color-3);
  border-radius: 60px;
  border: none;
  color: $white-color;
  font-size: $regularFont;
  padding: 4px 40px;
}

.btn-primary-2:hover {
  color: $white-color;
  box-shadow: 0px 0px 10px 0 $black-color-3;
}

.btn-primary-2:focus {
  box-shadow: none;
  outline: none;
}

.blog-btn {
  background-image: linear-gradient($lightblue-color, $blue-color-3);
  border-radius: 60px;
  border: none;
  color: $white-color !important;
  font-size: 13px !important;
  text-decoration: none;
  padding: 4px 15px 4px 15px !important;
}

.blog-btn:hover {
  color: $white-color !important;
  transition: all 0.5s ease-in-out !important;
  box-shadow: 0px 0px 10px 0 $black-color-3;
}

.blog-btn:focus {
  box-shadow: none;
  outline: none;
}

.img-box {
  position: relative;
}

.bb-circle-gradient {
  position: absolute;
  border-radius: 50%;
  background-color: rgba(var(--color-gradient-rgb), 0.7);
  box-shadow: 0px 0px 150px 50px $blue-color-2;
}

.bb-image-bg {
  position: absolute;
}

.bg-curve {
  margin-top: 0;
}

.slick-next {
  position: absolute !important;
  // top: -35px !important;
  // right: 3% !important;
}

.slick-prev {
  position: absolute !important;
  // top: -35px !important;
  // left: 91% !important;
}

.slick-prev:before,
.slick-next:before {
  font-family: FontAwesome !important;
  font-size: 38px !important;
  line-height: 1;
  opacity: 0.75;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev:before {
  content: "\f0d9" !important;
  color: #2dc279 !important;
}

.slick-next:before {
  content: "\f0da" !important;
  color: #2dc279 !important;
}

.hero-banner--two-col-style-3 {
  .container1 {
    .row {
      .main-wrapper {
        padding-bottom: 70px;
        position: relative;
        width: max-content;

        .bb-image {
          width: 600px;
          z-index: 2;
        }

        .line-wrapper {
          width: max-content;
          position: absolute;
          top: 79px;
          right: 160px;

          @keyframes linedrop-big {
            from {
              height: 0;
            }

            to {
              height: 230px;
            }
          }

          .line {
            background-color: #fff;
            height: 230px;
            width: 3px;
            position: relative;
            animation-name: linedrop-big;
            animation-duration: 10s;

            .dot {
              background-color: #fff;
              border-radius: 50%;
              height: 15px;
              width: 15px;
              position: absolute;
              left: -6px;
              bottom: -5px;
            }
          }
        }
      }

      h1 {
        text-align: left;
        color: $white-color;
        font-size: 3rem;
        margin: 70px 0 0 60px;

        span {
          font-weight: $semi-bold;
        }
      }

      .img-box {
        display: flex;
        justify-content: center;
        align-items: center;

        .bb-image {
          width: 80%;
        }
      }
    }
  }
}

@media (max-width: 1199px) {
  p {
    font-size: 1rem !important;
  }

  .hero-banner--two-col-style-3 {
    [class*="container"] + [class*="container"] {
      margin-top: 4rem;
    }

    .container1 {
      .row {
        [class*="col-"] {
          text-align: center;

          h1 {
            position: relative;
            z-index: 2;
            font-size: 2rem;
            margin: 4rem 0 0 0;
            text-align: center;
          }
        }

        .bb-image {
          width: 500px;
        }
      }
    }

    .container2 {
      .row {
        .img-box {
          .bb-image {
            width: 600px;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .hero-banner--two-col-style-3 {
    [class*="container"] + [class*="container"] {
      margin-top: 6rem;
    }

    .container1 {
      .row {
        [class*="col-"] h1 {
          margin: 2rem 0 0 0;
        }

        .main-wrapper {
          margin: auto;

          .bb-image {
            width: 290px;
          }

          .line-wrapper {
            top: 40px;
            right: 78px;

            @keyframes linedrop-big {
              from {
                height: 0;
              }

              to {
                height: 140px;
              }
            }

            .line {
              height: 140px;

              .dot {
                height: 15px;
                width: 15px;
                left: -6px;
                bottom: -5px;
              }
            }
          }
        }
      }
    }

    .container2 {
      .row {
        .img-box {
          .bb-image {
            width: 100%;
          }
        }
      }
    }
  }
}

@media (max-width: 580px) {
  .slick-list {
    margin-bottom: 10% !important;
  }
}

@import "./components/partials/Carousel/Carousel.scss",
  "./components/partials/Footer/Footer.scss",
  "./components/partials/Banner/Banner.scss",
  "./components/includes/About/About.scss",
  "./components/includes/PorchPiracy/PorchPiracy.scss",
  "./components/includes/HowItWorks/HowItWorks.scss",
  "./components/includes/Testimonials/Testimonials.scss",
  "./components/includes/Quote/Quote.scss",
  "./components/includes/Contact/Contact.scss",
  "./components/includes/Blogs/Blogs.scss",
  "./components/includes/WhenPackageIsStolen/WhenPackageIsStolen.scss",
  "./components/includes/BestDeliveryBox/BestDeliveryBox.scss",
  "./components/includes/PreventPackageTheft/PreventPackageTheft.scss",
  "./components/includes/DIYIdeasForPackage/DIYIdeasForPackage.scss",
  "./components/includes/StolenPackageTrend/StolenPackageTrend.scss",
  "./components/includes/SmartDeliveryBox/SmartDeliveryBox.scss",
  "./components/includes/WhenPorchPiratesStrike/WhenPorchPiratesStrike.scss",
  "./components/includes/PorchInHomeSecurity/PorchInHomeSecurity.scss",
  "./components/includes/CamerasAreNotEffective/CamerasAreNotEffective.scss",
  "./components/includes/PorchPiracyAGlobalIssue/PorchPiracyAGlobalIssue.scss",
  "./components/includes/PorchPiracyAStateFelony/PorchPiracyAStateFelony.scss",
  "./components/includes/PrivacyPolicy/PrivacyPolicy.scss",
  "./components/includes/Thankyou/Thankyou.scss",
  "./components/includes/OurTeam/OurTeam.scss",
  "./components/includes/HomeOverlay/Homeoverlay.scss",
  "./components/includes/Home/Home.scss",
  "./components/partials/Navbar/Navbar.scss";
