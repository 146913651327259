.about {

    section {
        overflow: hidden;
    }

    .text-with-bg {
        background: $background-gradient;
        border-radius: 30px;
        color: $white-color;
    }

    .section-1 {

        overflow: visible;

        .row {

            [class*="col-"] {
                position: relative;
                margin-top: -6rem;
            }
        }
    }

    .section-2 {
        margin-top: 6rem;
    }

    .hero-banner--two-col-style-4 {

        .bb-circle-gradient {
            width: 600px;
            height: 600px;
        }

        .bb-image-bg {
            width: 78%;
            height: 330px;
            border-radius: 70px;
        }

        .bb-image {
            border-radius: 70px;
        }

        .container+.container {
            margin-top: 6rem;
        }

        .container {
            .row+.row {
                margin-top: 3rem;
            }

            .row {

                align-items: center;

                .box1 {

                    h4 {

                        margin: 0;

                        font: {
                            size: 1.5rem;
                            weight: $semi-bold;
                        }
                    }

                    p {
                        font-size: 1.5rem;
                        margin: 20px 0 0 0;
                    }

                    a {
                        margin: 20px 0 0 0;
                    }
                }

                .box2 {

                    .img-box {

                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 400px;

                        .bb-image-bg {
                            background-color: $blue-color;
                            top: 0;
                            right: 0;
                        }

                        .bb-image {
                            position: absolute;
                            top: 35px;
                            z-index: 2;
                            width: 88%;
                            height: 365px;
                        }
                    }
                }

                ul {
                    list-style: none;
                    padding-left: 15px;
                    line-height: 2;
                    margin-bottom: 0;

                    li:before {
                        content: "";
                        background: $background-gradient;
                        display: block;
                        width: 25px;
                        height: 5px;
                        margin: 13px 25px 0 0;
                    }

                    li {
                        display: flex;

                        p {
                            width: 95%;
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }

        .container1 {

            .row {

                .box2 {

                    .img-box {

                        .bb-image {
                            left: 0;
                        }
                    }
                }
            }
        }

        .container2 {

            .row {

                .box2 {

                    .img-box {

                        .bb-image-bg {

                            right: auto;
                            left: 0;
                        }

                        .bb-image {
                            left: 60px;
                        }
                    }
                }
            }
        }

        .container:last-child {
            margin: {

                bottom: 6rem;
            }
        }

    }

    .section11 {
        min-height: 400px;
        width: 100%;
        padding: 110px 120px 100px;

        .wrapper1 {
            min-height: 400px;
            display: flex;
            flex-direction: column;
            justify-content: center;


            h6 {
                font-weight: $semi-bold;
                padding-bottom: 20px;
                font-size: $regularFont + 1;
            }

            p {
                line-height: 20px;
                padding-right: 30px;
                font-size: $regularFont - 1;
            }
        }

        .wrapper2 {
            min-height: 400px;

            display: flex;
            justify-content: center;
            align-items: center;

            .imgBox {

                min-height: 150px;

                img {
                    padding: 0px 0px 0px 80px !important;
                    width: 100%;
                    margin-bottom: 20px;
                }
            }
        }
    }

}


@media only screen and (max-width: 2900px) and (min-width: 1600px) {


    .about {

        .section11 {

            .wrapper2 {

                .imgBox {

                    min-height: 150px;

                    img {
                        padding: 0px 0px 0px 200px !important;
                    }
                }
            }
        }
    }


}

@media screen and (max-width: 1199px) {

    .about {

        .section-2 {
            margin-top: 3rem;
        }

        .hero-banner--two-col-style-4 {

            .container {
                .row {

                    .box1 {

                        h4 {
                            font-size: 1rem;
                            line-height: 1.5;
                            text-align: center;
                        }
                    }


                }
            }

            .container2 {

                .box1 {
                    margin-top: 3rem;
                }
            }

            .container1 {

                .box1 {
                    margin-top: 3rem;
                }
            }

            .container+.container {
                margin-top: 3rem;
            }

            .container:last-child {
                margin: {

                    bottom: 3rem;
                }
            }

        }

    }
}

@media screen and (max-width: 767px) {
    .about {

        .hero-banner--two-col-style-4 {

            .container {

                .row {

                    .box2 {

                        .img-box {

                            margin-top: 50px;
                            height: auto;

                            .bb-image-bg {
                                top: -40px;
                                width: 100%;
                                height: 200px;
                            }

                            .bb-image-bg,
                            .bb-image {
                                right: auto;
                                left: auto;
                            }

                            .bb-image {
                                position: static;
                                width: 90%;
                                height: auto;
                            }
                        }
                    }
                }
            }

        }

        .section11 {
            padding: 50px 35px;

            .wrapper1 {
                min-height: 400px;
                // background-color: aquamarine;
                padding-bottom: 20px;
                padding-top: 20px;
                padding-left: 0px !important;
                padding-right: 0px !important;

                h6 {
                    font-weight: $semi-bold;
                    padding-bottom: 20px;
                    font-size: $regularFont + 1;
                }

                p {
                    line-height: 18px;
                    font-weight: $bold;
                    padding-right: 0px !important;
                    text-align: justify;
                    font-size: $smallFont;
                }
            }

            .wrapper2 {
                min-height: 400px;
                //   background-color: brown;
                display: flex;
                justify-content: center;
                align-items: center;

                .imgBox {

                    min-height: 150px;

                    img {
                        padding: 0px 0px !important;
                    }
                }
            }
        }
    }
}