@media only screen and (min-width: 990px) and (max-width: 1536px) {
    .top-banner-content {
        position: absolute;
        right: -100px;
        top: 70px;
        left: 550px;
        min-height: 500px;
        background-color: white;
        padding: 70px 100px 70px 60px;
        border-bottom-left-radius: 30px;
        border-top-left-radius: 30px;
    }

    .text-with-bg-style-2{
        min-height: 600px;
        max-height: 900px;
        background-position: center;
        background-repeat: no-repeat;
        margin-bottom: 0px !important;
    }

    .howItWorks .text-with-bg-style-2 .wrapper .row
    {
        justify-content:flex-start !important;
    }
}
