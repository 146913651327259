.quote {
  .banner-style-3 .wrapper .row .col-lg-6 {
    margin-top: 181px;
  }

  .section1 {
    min-height: 400px;
    //  background-color:bisque;
    width: 100%;
    padding: 110px 120px 100px;
    // background-color: $blue-color;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    margin: 50px 0px 0px;
    border-top-right-radius: 50px;
    border-top-left-radius: 50px;
    // margin-bottom: 150px;

    .wrapper1 {
      min-height: 400px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      h1 {
        color: $white-color;
        text-align: center;
        font-size: $mediumHeading + 5;
      }

      .backLink {
        color: $white-color;
        text-decoration: none;
        font-size: $mediumFont + 2;
        text-align: center;

        i {
          color: $white-color;
          padding-right: 5px;
          transition: 0.3s ease-in-out;
        }

        &:hover {
          i {
            margin-left: -10px;
            transition: 0.3s ease-in-out;
          }
        }
      }

      label {
        color: $white-color;
        margin-left: 2px;
      }

      input,
      textarea {
        border-radius: 5px;
        outline: none;
        box-shadow: none;
      }

      // input:invalid
      // {
      // border: 2px solid red;
      // }

      // input:valid {
      //     background: rgb(143, 191, 250);
      // }

      .nav-request {
        background-color: $light-purple-color;
        color: $white-color !important;
        font-weight: $regular !important;
        outline: none;
        box-shadow: none;
        min-width: 200px;
        padding: 10px 20px;
        border-radius: 25px;

        font-size: 17px !important;
        border: 2px solid $light-purple-color;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .quote {
    .section1 {
      padding: 50px 0px;
      margin-bottom: 100px;
    }
  }
}

@media screen and (max-width: 3000px) {
  .google-captcha {
    margin-top: 4%;
    display: flex;
    place-content: center;
  }
}
