.blog-card{
    background-color: #0a1728;
    border: none;
    border-radius: 30px 30px 15px 15px;
    // background-image: linear-gradient(blue, black);
    margin: 10px;
    min-height: 370px !important;

    .card-img-top{
        border-top-right-radius: 15px;
        border-top-left-radius: 15px;
        height: auto;
        color: white;
    }

    .blog-card-title{
        text-align: center;
        color: white;
        font-weight: 700;
    }

    .blog-card-text{
        text-align: center;
        color: white;
        font-weight: 500;
        padding-bottom: 5%; // to manage the placement of buttons 
    }

    .top-card-btn{
        color: white !important;
    }
}