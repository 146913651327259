.hero-banner--two-col-style-1:root {
  --color-gradient-rgb: 34, 40, 105;
}

@mixin on-hover-card-style-change {
  .card-body {
    .overlay {
      height: 100%;
    }

    .card-img-top {
      filter: brightness(0) invert(1);
    }

    .card-title,
    .card-text {
      color: $white-color;
    }

    .card-text {
      display: block;
    }
  }
}

.home {
  .ph_font_size {
    font-size: 20px;
  }

  .bb-circle-gradient {
    width: 600px;
    height: 600px;
  }

  .bb-image-bg {
    width: 470px;
    height: 505px;
    border-radius: 70px;
  }

  [class*="section"] {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  section {
    overflow: hidden;
  }

  .section-1-sub {
    background: {
      color: $tranparent-bg;
    }

    [class*="container"] + [class*="container"] {
      margin-top: 250px;
    }
  }

  .section-1-sub:first-child {
    padding: 0rem 2rem;
  }

  .section-1-sub + .section-1-sub {
    padding: {
      top: 6rem;
      bottom: 13rem;
    }
  }

  .section-2 {
    background-color: $background-color1;
    padding: 5rem 0 0;
  }

  .section-4 {
    background-color: $background-color1;
    padding: 3rem 0 0;
  }

  .section-5 {
    position: relative;
    overflow: visible;
  }

  .hero-banner--one-col-centered-style-1 {
    .wrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .bb-circle-gradient-home {
        width: 550px;
        height: 550px;
      }
      .bb-circle-gradient-home {
        background-color: #1180ff;
        position: absolute;
        border-radius: 50%;
        top: 100px;
      }

      img,
      h1,
      img + h1 + div {
        z-index: 1;
      }

      h1 {
        color: $white-color;
        font-weight: $thin;
        margin-bottom: 0;

        b {
          font-weight: $semi-bold;
        }

        span {
          font: {
            size: 7rem;
            weight: $heavy-bold;
          }
        }
      }

      .row {
        [class*="order-"]:not(.order-1) {
          margin-top: 30px;
        }

        [class*="col-"] {
          display: flex;
          align-items: center;
          justify-content: center;

          .q-text {
            font-size: 1.5rem;
          }

          .q-text,
          .q-mark {
            color: $white-color;
            margin-bottom: 0;
          }

          .q-mark {
            font-size: 7rem;
            font-weight: 800;
          }
        }
      }
    }
  }

  .hero-banner--two-col-style-1 {
    min-height: 350px;
    background-color: $white-color;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .container1 {
      padding: 0;
      margin: 0;

      .wrapper {
        padding: 70px 120px;
        min-height: 180px;
        // background-color: rgb(189, 189, 130);
        background-color: $black-color-2;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0px;

        .box1 {
          padding-right: 35px;
          // min-height: 350px;
          // background-color: burlywood;
          margin: 0px;
          color: $white-color;

          h3 {
            font-size: $heading2;
            font-weight: $heavy-bold;
            letter-spacing: 0.5px;
            text-transform: uppercase;
            margin-bottom: 20px;
          }

          p,
          .moreLink {
            font-size: $mediumFont;
            font-weight: $regular;
            padding-right: 25px;
            text-decoration: none;
            color: $white-color;

            i {
              margin-left: 5px;
              font-size: $mediumFont;
            }
          }
        }

        .box2 {
          // min-height: 350px;
          // background-color: rgb(124, 76, 13);
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0px;
          padding: 0px;

          .img-box {
            min-height: 180px;
            width: 100%;
            //background-color: cornflowerblue;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover !important;
            }
          }
        }
      }
    }

    .container2 {
      padding: 0px;
      margin: 0px;

      .wrapper {
        min-height: 180px;
        padding: 70px 120px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0px;
        background-size: cover;
        //background-attachment: fixed;
        //background-color: $black-color-2;
        background-repeat: no-repeat;
        background-position: center;

        .box1 {
          // min-height: 350px;
          // background-color: rgb(124, 76, 13);
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0px;
          padding: 0px;

          .img-box {
            min-height: 180px;
            width: 100%;
            //background-color: cornflowerblue;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover !important;
            }
          }
        }

        .box2 {
          // min-height: 350px;
          // background-color: burlywood;
          margin: 0px;
          padding-left: 70px;

          h3 {
            font-size: $heading2;
            font-weight: $heavy-bold;
            letter-spacing: 0.5px;
            text-transform: uppercase;
            margin-bottom: 20px;
            color: $black-color-2;
          }

          p {
            font-size: $mediumFont;
            font-weight: $medium;
            color: $black-color-2;
          }
        }
      }
    }
  }

  .hero-banner--two-col-style-2 {
    .container1 {
      .box1 {
        color: $white-color;
        z-index: 1;

        img {
          width: 250px;
        }

        h1 {
          font: {
            size: 3rem;
            weight: $heavy-bold-2;
          }

          margin-bottom: 0;
        }

        p {
          line-height: 1.8;
          margin-bottom: 0;
        }
      }

      .box2 {
        .img-box {
          .bb-circle-gradient {
            top: -70px;
          }

          .bb-image-bg {
            background-image: linear-gradient($blue-color-3, $lightblue-color);
            left: 75px;
          }

          .bb-image {
            position: absolute;
            top: 75px;
            width: 500px;
          }
        }
      }
    }

    .container2 {
      .box1 {
        .bb-image {
          width: 100%;
        }
      }

      .box2 {
        color: $white-color;

        .boxWrap {
          position: relative;
          z-index: 1;
          margin-top: 50px;

          h3 {
            font: {
              size: 2rem;
              weight: $semi-bold;
            }
          }

          hr {
            background-color: $lightblue-color;
            height: 2px;
            opacity: 1;
          }

          ul {
            list-style: none;
            padding: 0;
            line-height: 4;

            li {
              display: flex;
              align-items: center;
              font-weight: 600;

              img {
                width: 40px;
                margin-right: 40px;
              }

              h6 {
                margin: 0;
                line-height: 2;
              }
            }
          }
        }
      }
    }
  }

  .hero-banner--two-col-style-4 {
    .row {
      .box1 {
        padding: {
          top: 170px;
          bottom: 170px;
        }

        h4 {
          font: {
            size: 1.5rem;
            weight: $semi-bold;
          }

          margin: 0;
        }

        p {
          font-size: 1.5rem;
          margin: 20px 0 0 0;
        }

        a {
          margin: 20px 0 0 0;
        }
      }

      .box2 {
        .img-box {
          display: flex;
          justify-content: center;
          align-items: center;

          .bb-image-bg {
            background-color: $blue-color;
            top: 20px;
            right: 0;
          }

          .bb-image {
            position: absolute;
            top: 105px;
            z-index: 2;
            width: 470px;
            border-radius: 70px;
          }
        }
      }
    }

    .container2 {
      .box1 {
        padding-top: 100px;
      }

      .box2 {
        .img-box {
          .bb-image-bg {
            right: auto;
            left: 0;
          }
        }
      }
    }
  }

  .hero-banner--two-col-style-5 {
    padding: 2rem 0;

    .row {
      .box1 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        color: $white-color;

        h4 {
          font-weight: $semi-bold;
        }
      }

      .box2 {
        .img-box {
          width: 100%;
          height: 180px;
          background-position: center;
          background-size: cover;
          border-radius: 100px;
        }
      }
    }
  }

  .hero-banner--two-col-style-6 {
    .bg-curve {
      position: relative;
      z-index: 2;
    }

    .container {
      position: relative;
      z-index: 1;
      margin-top: -120px;

      .row {
        [class*="col-"]:first-child {
          .img-box {
            height: 750px;

            @keyframes backdrop {
              from {
                height: 0;
              }

              to {
                height: 700px;
              }
            }

            .bb-image-bg-style-2 {
              width: 370px;
              max-width: 100%;
              height: 700px;
              background: $background-gradient;
              border-bottom-left-radius: 15px;
              border-bottom-right-radius: 15px;
              animation-name: backdrop;
              animation-duration: 10s;
            }

            .bb-image {
              position: absolute;
              width: 600px;
              max-width: 100%;
              top: 130px;
              left: 20px;
            }
          }
        }

        [class*="col-"] + [class*="col-"] {
          display: flex;
          flex-direction: column;
          justify-content: center;

          .text-wrapper {
            color: $white-color;
            margin-top: 20px;
            padding: 100px 20px 20px;
            text-align: center;

            h1 {
              margin-top: 0;
            }

            p {
              margin: 30px 0 0;
            }
          }
        }
      }
    }

    .bg-white {
      height: 70px;
    }
  }

  .large-bg-img--with-centered-text {
    min-height: 350px;

    //background-color: $black-color-2;
    background: {
      position: center;
      size: cover;
      repeat: no-repeat;
    }

    width: 100%;
    padding: 80px 0px 70px;
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    p {
      color: $white-color;
      padding: 10px 0px;
      text-align: justify;

      font: {
        size: $regularFont;
        weight: $medium;
      }
    }
  }

  .large-img--full-width {
    min-height: 100px;
    background-color: $black-color-2;
    width: 100%;

    img {
      width: 100%;
    }
  }

  .three-col--img-with-text-style-1 {
    min-height: 250px;
    background-color: $white-color;
    width: 100%;
    padding: 70px 120px 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .heading {
      h3 {
        font-weight: $heavy-bold;
        text-align: center;
        margin-bottom: 30px;
      }
    }

    .content {
      min-height: 250px;

      .box {
        // border: 1px solid black;
        display: flex;
        flex-direction: column;
        padding: 20px 30px;

        .img-block {
          min-width: 150px;
          // min-height: 100px;
          height: auto;
          // background-color: aqua;

          img {
            width: 100%;
            height: auto;
          }
        }

        .img-content {
          min-width: 150px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          padding: 40px 0px 0px;

          h5 {
            font-size: $mediumFont;
            font-weight: $heavy-bold;
            letter-spacing: 0.5px;
            text-transform: uppercase;
          }

          p {
            text-align: center;
            line-height: 20px;
          }
        }
      }
    }
  }

  .three-col--img-with-text-style-2 {
    .row + .row {
      margin-top: 4rem;
    }

    .row {
      h1 {
        font: {
          size: 3rem;
          weight: $semi-bold;
        }

        color: $blue-color;
        margin-bottom: 0;
      }

      .card {
        border: none;
        border-radius: 20px;
        box-shadow: 10px 15px 20px -5px $black-color-3;
        height: 450px;

        .card-body {
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;

          .overlay {
            position: absolute;
            bottom: 0;
            background: $background-gradient;
            border-radius: 20px;
            width: 100%;
            height: 0%;
            transition: all linear 0.1s;
          }

          .content {
            z-index: 1;
          }

          .card-img-top {
            filter: brightness(1) invert(1);
            width: 100px;
          }

          .card-title {
            font: {
              size: 1.5rem;
              weight: $semi-bold;
            }

            color: $darkgrey-color;

            margin: 1rem 0 0;
          }

          .card-text {
            display: none;
            margin: 1rem 0 0;
            transition: all 0.2s;
          }
        }
      }

      .card:hover {
        @include on-hover-card-style-change;
      }
    }

    .bg-curve {
      margin-top: 0;
    }
  }

  .large-banner--with-text-align-right {
    min-height: 320px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;

    .parent-wrapper {
      min-height: 320px;
      background: linear-gradient(
        to left,
        rgb(46, 8, 83),
        rgba(0, 0, 0, 0.2)
      ) !important;

      .wrapper {
        min-height: 320px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px 45px;

        h3 {
          color: $white-color;
          text-transform: uppercase;
          letter-spacing: 1px;
          word-spacing: 3px;
          font-size: $largeHeading - 4;
          font-weight: $semi-bold;
          margin-top: 10px;
        }
      }
    }
  }

  .large-bg-img--full-width {
    min-height: 800px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
  }

  .hero-banner--one-col-centered-style-2 {
    min-height: 250px;
    background-color: $white-color;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover !important;
    background-attachment: fixed !important;
    background-color: $black-color-2;
    background-repeat: no-repeat !important;
    background-position: center !important;
    padding: 70px 100px 80px;

    h1 {
      color: $white-color;
      text-transform: uppercase;
      letter-spacing: 2px;
      word-spacing: 3px;
      font-size: $mediumHeading + 2;
      font-weight: $semi-bold;
    }

    p {
      font-size: $mediumFont - 2;
      // text-transform: uppercase;
      //letter-spacing: 1px;
      word-spacing: 1px;
      text-align: center;
      font-weight: $regular;
      text-decoration: none;
      color: $white-color;
      margin-top: 12px;
      margin-bottom: 25px;
      width: 57%;
    }

    .more-btn {
      text-decoration: none;
      color: $white-color;
      font-size: $regularFont;
      background-color: $black-color-2;
      padding: 8px 20px 8px 31px;

      i {
        margin-left: 8px;
        transition: 0.3s ease-in-out;
      }

      &:hover {
        i {
          margin-left: 13px;
          transition: 0.3s ease-in-out;
        }
      }
    }
  }

  .text-with-bg-style-4 {
    background-blend-mode: overlay;
    background-color: #09182dd4;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: visible;
    padding-bottom: 3rem;

    .multi-assets-wrapper {
      position: relative;

      .bb-wave {
        position: absolute;
        top: 50px;

        img {
          width: 600px;
        }
      }

      .bg-line {
        background: $background-gradient;
        width: 100%;
        height: 110px;
        margin-top: 40px;
      }
    }

    .container {
      .row + .row {
        margin-top: 6rem;
      }

      .row {
        .section-heading {
          color: $white-color;
          font-size: 5rem;
          margin-top: -40px;
          margin-bottom: 0;
          position: relative;
        }

        h3.section-heading {
          font-size: 3rem;
        }

        p {
          color: $white-color;
          margin: 20px 0 0;
        }
      }

      .row.features-row {
        margin: 0;

        [class*="col-"] {
          margin-top: 60px;

          .content-wrapper {
            text-align: center;

            img {
              width: 70px;
            }

            .text {
              color: $white-color;
              font-size: 1rem;
              margin: 10px 0 0;
            }
          }
        }
      }
    }
  }
}

/******************************
******* Responsiveness ********
******************************/

@media only screen and (max-width: 1420px) and (min-width: 992px) {
  .home {
    .hero-banner--two-col-style-1 {
      .container2 {
        .wrapper {
          padding: 70px 120px 150px !important;
        }
      }
    }

    .large-banner--with-text-align-right {
      .parent-wrapper {
        .wrapper {
          padding: 0px 20px 0px 0px !important;

          h3 {
            margin-left: -50px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 2900px) and (min-width: 1600px) {
  .home {
    .hero-banner--two-col-style-1 {
      .container1 {
        .wrapper {
          padding: 70px 150px !important;

          .box1 {
            h3 {
              font-size: $mediumHeading - 5 !important;
            }

            p,
            .moreLink {
              font-size: $largeFont + 1 !important;
            }
          }
        }
      }

      .container2 {
        .wrapper {
          padding: 70px 150px !important;

          .box2 {
            // min-height: 350px;
            // background-color: burlywood;
            margin: 0px;
            padding-left: 80px !important;

            h3 {
              font-size: $mediumHeading - 5 !important;
            }

            p {
              font-size: $largeFont + 1 !important;
            }
          }
        }
      }
    }

    .large-banner--with-text-align-right {
      .parent-wrapper {
        min-height: 390px;
      }
    }

    .hero-banner--one-col-centered-style-2 {
      p {
        font-size: $mediumFont - 2;
        width: 100% !important;
      }
    }
  }
}

@media screen and (max-width: 1399px) {
  .home {
    .hero-banner--two-col-style-6 {
      .container {
        .row {
          [class*="col-"]:first-child {
            .img-box {
              height: 700px;

              @keyframes backdrop {
                from {
                  height: 0;
                }

                to {
                  height: 650px;
                }
              }

              .bb-image-bg-style-2 {
                height: 650px;
              }

              .bb-image {
                width: 600px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1199px) {
  .home {
    .section-1-sub + .section-1-sub {
      padding: {
        top: 3rem;
        bottom: 3rem;
      }
    }

    .section-1-sub:first-child {
      padding: {
        top: 2rem;
        bottom: 3rem;
      }
    }

    .section-1-sub {
      [class*="container"] + [class*="container"] {
        margin-top: 528px;
      }
    }

    .section-2,
    .section-3 {
      padding: 3rem 0 0;
    }

    .section-3 {
      padding-top: 0;
      padding-bottom: 3rem;
    }

    .hero-banner--one-col-centered-style-1 {
      .wrapper {
        .bb-circle-gradient {
          width: 300px;
          height: 300px;
        }
      }
    }

    .hero-banner--two-col-style-2 {
      .container1 {
        .box1 {
          text-align: center;

          img {
            width: 180px;
            margin: 0 auto;
          }

          h1 {
            font-size: 2rem;
          }

          p {
            font-size: 1rem;
            margin-bottom: 0;
          }
        }

        .box2 {
          margin-top: 3rem;

          .img-box {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 442px;

            .bb-circle-gradient {
              width: 600px;
              height: 600px;
            }

            .bb-image-bg {
              width: 400px;
              height: 430px;
              left: auto;
              top: 25px;
              right: 180px;
            }

            .bb-image {
              top: 35px;
              width: 400px;
            }
          }
        }
      }

      .container2 {
        .box1 {
          margin-top: 3rem;

          .img-box {
            display: flex;
            justify-content: center;
            align-items: center;

            .bb-circle-gradient {
              height: 250px;
            }

            .bb-image,
            .bb-circle-gradient {
              width: 400px;
            }
          }
        }

        .box2 {
          .boxWrap {
            margin-top: 0px;

            h3 {
              text-align: center;
              margin-bottom: 0;
            }

            hr {
              margin: 2rem 0 0;
            }

            ul {
              margin: 2rem 0 0 auto;
              width: 670px;
            }
          }
        }
      }
    }

    .hero-banner--two-col-style-4 {
      .row {
        .box1 {
          padding: {
            top: 0px;
            bottom: 0px;
          }

          text-align: center;
        }

        .box2 {
          margin-top: 80px;

          .img-box {
            .bb-image-bg {
              left: auto;
              top: 0px;
              right: 180px;
            }

            .bb-image {
              position: absolute;
              top: 105px;
              z-index: 2;
              width: 470px;
              border-radius: 70px;
            }
          }
        }
      }

      .container2 {
        margin-top: 40rem;
        margin-bottom: 35rem;
      }
    }

    .hero-banner--two-col-style-5 {
      margin-bottom: -5px;
      padding: 4rem 0;

      .row {
        .box1 {
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
        }

        .box2 {
          margin-top: 50px;
        }
      }
    }

    .hero-banner--two-col-style-6 {
      .container {
        margin-top: -105px;

        .row {
          [class*="col-"]:first-child {
            .img-box {
              display: flex;
              flex-direction: column;
              align-items: center;
              height: 750px;

              .bb-image {
                left: auto;
              }
            }
          }

          [class*="col-"] + [class*="col-"] {
            .text-wrapper {
              padding-top: 80px;
              padding-bottom: 80px;
            }
          }
        }
      }

      .bg-white {
        height: 5px;
      }
    }

    .three-col--img-with-text-style-2 {
      .row + .row {
        margin-top: 2rem;
      }

      .row {
        [class*="col-"] {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        [class*="col-"] + [class*="col-"] {
          margin-top: 3rem;
        }

        .box2 {
          .bb-image-bg {
            right: auto;
          }
        }

        .card {
          max-width: 350px;
          width: inherit;
          height: 300px;

          @include on-hover-card-style-change;

          .card-body {
            .card-img-top {
              width: 60px;
            }
          }
        }

        h1 {
          font-size: 2rem;
        }
      }

      .bg-curve {
        margin-top: 0;
      }
    }

    .text-with-bg-style-4 {
      .multi-assets-wrapper .bb-wave {
        display: flex;
        justify-content: center;
        width: 100%;

        img {
          width: 800px;
          max-width: 100%;
        }
      }

      .container .row h1.section-heading {
        margin-top: 10rem;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .home {
    .hero-banner--two-col-style-6 .container {
      margin-top: -65px;
    }
  }
}

@media screen and (max-width: 767px) {
  .home {
    .section-1-sub {
      [class*="container"] + [class*="container"] {
        margin-top: 6rem;
      }
    }

    .section-1-sub + .section-1-sub {
      padding-bottom: 5rem;
    }

    .bb-circle-gradient {
      width: 250px !important;
      height: 250px !important;
    }

    .large-bg-img--with-centered-text {
      padding: 50px 20px 40px !important;

      p {
        font-size: $smallFont !important;
        font-weight: $regular !important;
      }
    }

    .three-col--img-with-text-style-1 {
      padding: 65px 35px 65px !important;

      .content {
        .box {
          padding: 20px 0px;

          .img-content {
            p {
              line-height: 20px;
              font-size: $smallFont;
              font-weight: $bold;
            }
          }
        }
      }
    }

    .large-banner--with-text-align-right {
      background-position: 35% 50%;

      .parent-wrapper {
        background: linear-gradient(
          to left,
          rgb(62, 25, 99),
          rgba(0, 0, 0, 0.5)
        ) !important;

        .wrapper {
          h3 {
            color: $white-color;
            text-transform: uppercase;
            letter-spacing: 1px;
            word-spacing: 3px;
            font-size: $mediumHeading - 2;
            margin-top: 10px;
            text-align: center;
          }
        }
      }
    }

    .large-bg-img--full-width {
      min-height: 430px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      width: 100%;
      margin-top: 20px;
    }

    .hero-banner--one-col-centered-style-1 {
      .wrapper {
        margin-bottom: 0 !important;
      }
    }

    .hero-banner--one-col-centered-style-2 {
      padding: 70px 15px;

      h1 {
        color: $white-color;
        text-transform: uppercase;
        letter-spacing: 2px;
        word-spacing: 3px;
        font-size: $mediumHeading - 2;
        font-weight: $semi-bold;
      }

      p {
        font-size: $mediumFont - 3;
        width: 80%;
      }
    }

    .hero-banner--two-col-style-1 {
      .container1 {
        .wrapper {
          padding: 65px 35px 65px !important;

          .box1 {
            padding-right: 0px !important;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            h3 {
              font-size: $heading1 + 2;
              text-align: center;
            }

            p,
            .moreLink {
              font-size: $smallFont + 2 !important;
              font-weight: $bold !important;
              text-align: center;
              padding-right: 0px !important;

              i {
                font-size: $xsmallFont !important;
              }
            }
          }

          .box2 {
            .img-box {
              margin-top: 30px;
              min-height: 140px !important;
            }
          }
        }
      }

      .container2 {
        .wrapper {
          padding: 65px 35px 65px !important;
          background-position: center center !important;

          .box1 {
            .img-box {
              margin-top: 30px;
              min-height: 140px !important;
            }
          }

          .box2 {
            padding-left: 0px !important;

            h3 {
              font-size: $heading1 + 2;
              text-align: center;
            }

            p {
              font-size: $smallFont + 2 !important;
              font-weight: $medium !important;
              text-align: center;
            }
          }
        }
      }
    }

    .hero-banner--two-col-style-2 {
      .container1 {
        .box2 {
          .img-box {
            height: 100%;

            margin-top: 3rem;

            .bb-image-bg {
              right: auto;
              width: 100%;
              height: 70%;
              top: 0px;
            }

            .bb-image {
              position: static;
              width: 90%;
            }
          }
        }
      }

      .container2 {
        .box1 {
          .img-box {
            .bb-image {
              width: 100%;
            }
          }
        }

        .box2 {
          .boxWrap {
            ul {
              width: 100%;
            }
          }
        }
      }
    }

    .hero-banner--two-col-style-4 {
      .row {
        .box2 {
          .img-box {
            .bb-image-bg {
              width: 100%;
              top: -40px;
              right: auto;
              height: 290px;
            }

            .bb-image {
              position: static;
              width: 90%;
            }
          }
        }
      }

      .container2 {
        margin: {
          top: 3rem;
          bottom: 3rem;
        }
      }
    }

    .hero-banner--two-col-style-6 .container {
      margin-top: -60px;

      .row {
        [class*="col-"]:first-child .img-box {
          height: 670px;

          @keyframes backdrop {
            from {
              height: 0;
            }

            to {
              height: 550px;
            }
          }

          .bb-image-bg-style-2 {
            height: 550px;
            width: 100%;
          }

          .bb-image {
            left: auto;
            width: 90%;
          }
        }

        [class*="col-"] + [class*="col-"] .text-wrapper {
          padding-top: 0;
        }
      }
    }

    .text-with-bg-style-4 {
      .multi-assets-wrapper .bb-wave {
        img {
          width: 500px;
        }
      }

      .container {
        .row + .row {
          margin-top: 1rem;
        }

        .row {
          .section-heading {
            font-size: 2rem;
          }

          h1.section-heading {
            margin-top: 3rem;
          }

          h3.section-heading {
            font-size: 2rem;
            margin-top: 3rem;
          }
        }

        .row.features-row [class*="col-"] {
          margin-top: 60px;
        }

        .row.features-row [class*="col-"]:first-child {
          margin-top: 2rem;
        }
      }
    }

    // .slick-next {
    //     right: 5% !important;
    //     top: -30px !important;
    // }

    // .slick-prev {
    //     left: 84% !important;
    //     top: -30px !important;
    // }

    // .slick-prev:before,
    // .slick-next:before {

    //     font-size: 32px !important;
    // }
  }
}

@media screen and (max-width: 575px) {
  .home {
    .large-bg-img--full-width {
      min-height: 250px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      width: 100%;
      margin-top: 30px;
    }

    .hero-banner--one-col-centered-style-2 {
      h4 {
        font-size: $xsmallHeading;
        margin-left: 30px !important;
      }
    }

    .hero-banner--two-col-style-2 {
      .container2 {
        .box2 {
          .boxWrap {
            ul {
              line-height: 5;
            }
          }
        }
      }
    }

    .hero-banner--two-col-style-6 {
      .container {
        margin-top: -30px;

        .row [class*="col-"]:first-child .img-box {
          height: 420px;

          @keyframes backdrop {
            from {
              height: 0;
            }

            to {
              height: 310px;
            }
          }

          .bb-image-bg-style-2 {
            width: 300px;
            height: 310px;
          }

          .bb-image {
            top: 80px;
            width: 275px;
          }
        }
      }

      .bg-white {
        height: 5px;
      }
    }

    .text-with-bg-style-4 {
      .multi-assets-wrapper .bb-wave {
        img {
          width: 500px;
        }
      }
    }

    // .slick-next {
    //     right: 7% !important;
    //     top: -28px !important;
    // }

    // .slick-prev {
    //     left: 77% !important;
    //     top: -28px !important;
    // }

    // .slick-prev:before,
    // .slick-next:before {

    //     font-size: 30px !important;
    // }
  }
}
// logo style
.logo-style {
  width: 150px;
}
// section 1
.heading_gr {
  color: #8ff380;
  font-size: 50px;
}
img.mx-3 {
  width: 70px;
}
.content-wrapper .text {
  color: #fff;
  font-size: 1.1rem;
  margin: 10px 0 0;
}
.row.features-row {
  margin: 0;
}
.row.features-row [class*="col-"] {
  margin-top: 60px;
}
.features-row .content-wrapper {
  text-align: center;
}
section.section-1 {
  padding-bottom: 85px;
}
img.banerbox-image {
  width: 480px;
  margin-top: -410px;
  margin-left: -46px;
}
img.banerbox-image-2 {
  width: 280px;
  margin-top: -248px;
  margin-left: 235px;
}

img.home-banner-1.aos-animate {
  width: 520px;
  margin-top: 90px;
}
.carousel-control-next {
  display: none;
}
.carousel-control-prev {
  display: none;
}
.carousel {
  // height: 13% !important;
  width: 105% !important;
  position: relative !important;
}
.carousel-item img {
  // height: 763px !important;
  width: 100% !important;
}
.carousel-indicators {
  display: none;
}
.sec3-rs {
  margin-left: -12px;
}
.sec3-h1 {
  color: white;
  font-weight: 700;
  font-size: 2.5rem;
}
.sec3-ls {
  padding: 50px 100px;
  background-size: cover;
}
.sec3-p1 {
  color: white;
  font-size: 18px;
}
.sec3-btn-green {
  background-image: linear-gradient(#3aca7a, #27c179);
  border-radius: 60px;
  border: none;
  color: #fff;
  font-size: 16px;
  padding: 7px 25px;
}
.sec3-btn-green:hover {
  color: #fff;
  box-shadow: 0px 0px 10px 0 #919191;
}

// section 5
.bb-image-bg-style-sec5 {
  @keyframes sec5backdrop {
    from {
      height: 0;
    }

    to {
      height: 1000px;
    }
  }
  width: 200px;
  max-width: 100%;
  height: 1000px;
  background: $background-gradient;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  animation-name: sec5backdrop;
  animation-duration: 5s;
}
img.bb-image-sec5 {
  position: absolute;
  top: 280px;
  left: -221px;
  width: 700px;
}
.sec5-extra-css {
  height: 1140px;
}
.sec5-cs {
  margin-top: 100px;
}
.sec5-h1 {
  font-size: 30px;
  font-weight: 600;
}
// for mobile devices
@media only screen and (max-width: 600px) {
  .mb-f-res {
    padding: 0px 35px !important;
  }
  .heading_gr_mb {
    color: #072e5b;
  }
  .bb-circle-gradient-home {
    width: 400px !important;
    height: 400px !important;
  }
  .home-banner-1 {
    margin-top: 155px !important;
    width: 360px !important;
  }
  img.banerbox-image.aos-init.aos-animate {
    margin-top: -253px;
    width: 340px;
  }
  img.banerbox-image-2.aos-init.aos-animate {
    margin-top: -253px;
    width: 340px;
  }
  .sec3-ls {
    padding: 35px 60px;
  }
  img.mb-responsive {
    width: 450px;
  }
  .sec5-cs {
    margin: 20px 35px 0px 35px;
  }
  .sec3-btn-green {
    margin: 0% 18% !important;
  }
  @keyframes sec5mbdrop {
    from {
      height: 0;
    }

    to {
      height: 500px;
    }
  }
  .bb-image-bg-style-sec5 {
    margin: 50px auto !important;
    height: 500px;
    animation-name: sec5mbdrop;
  }
  .bb-image-sec5.aos-init.aos-animate {
    width: 300px;
    margin-left: 250px !important;
    margin-top: -120px !important;
  }
  .sec5-extra-css {
    height: 870px;
    margin-top: 400px;
  }
  .carousel-item > img {
    height: 450px !important;
    width: 450px !important;
  }
  .carousel {
    position: absolute !important;
    height: 6% !important;
    width: 100% !important;
  }
}
.section-7 {
  background-color: #f2f2f2;

  hr:not([size]) {
    height: 5px;
  }

  hr {
    background: $background-gradient;
    opacity: 1;
    margin: 0;
  }

  .container {
    padding: 30px 0 30px 0;
  }

  .blog-section-title {
    p {
      font: {
        size: 3rem;
        weight: $semi-bold;
      }

      color: #091a36;
      margin-bottom: 0;
      text-align: center;
      font-weight: 700;
    }
  }
}

.blog-carousel {
  margin-top: 5% !important;

  .btn .btn-primary-2 .blog-section-btn {
    text-align: center !important;
    // margin-left: 45%;
  }
}

@media (max-width: 580px) {
  .blog-carousel {
    margin-top: 15% !important;
  }

  .section-7 {
    .blog-section-title {
      p {
        font: {
          size: 2rem !important;
        }
      }
    }
  }
}

@media (min-width: 368px) and (max-width: 600px) {
  img.banerbox-image {
    margin-left: -24px !important;
  }

  img.banerbox-image-2 {
    width: 230px !important;
    height: 190px !important;
    margin-top: -190px !important;
    margin-left: 130px !important;
  }
}

@media (max-width: 320px) {
  .bb-circle-gradient-home {
    width: 550px;
    height: 550px;
  }

  img.home-banner-1.aos-animate {
    width: 300px !important;
  }

  img.banerbox-image {
    margin-left: -24px !important;
    width: 280px !important;
  }

  img.banerbox-image-2 {
    width: 170px !important;
    height: 140px !important;
    margin-top: -140px !important;
    margin-left: 130px !important;
  }

  img.banerbox-image.aos-init.aos-animate {
    margin-top: -240px !important;
  }
}

@media (max-width: 390px){
  .bb-image-bg
  {
    margin-top: -25px;
  }
}